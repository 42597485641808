import type { SelectOptions } from './index'
// findOne
export const findOption = (id: string, dataAll: any[]) => {
  let result: any = false
  dataAll.forEach((list: any) => {
    list.forEach((option: any) => {
      if (option.id == ~~id) {
        result = option
      }
    })
  })

  return result as SelectOptions
}

export const filterChildren = (id: string, dataAll: any[]) => {
  const result: any[] = []
  dataAll.forEach((list: any) => {
    list.forEach((option: any) => {
      if (~~option.parent === Number(id)) {
        result.push(option)
      }
    })
  })

  // console.log(result)

  return result
}