import cx from "classnames";
import styles from "./index.module.less";
import { BottomLine } from "../../pages/project-view"
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <BottomLine />
      <div className={styles.links}>
        <div className={cx("container", styles.spread)}>
          {/* <Link to="/">首页</Link> */}
          <Link to="/projectView">项目统计</Link>
          <a href="/materialStatistics">构件统计</a>
          <a href="/totalPackageStatistics">工序统计</a>
          {/* <div className={styles.logo}></div> */}
          <a href="/allStatistics">总包统计</a>
          <a href="/supplierStatistics">材料统计</a>
          <a href="/largeScreen">数据大屏</a>
          {/* <a href="/">Resources</a> */}
        </div>
      </div>
      <BottomLine />
      <div className={styles.cp}>
        <div className={cx("container", styles.extra)}>
          <div className={styles.socials}>
            <div className={styles.social}></div>
            <div className={styles.social}></div>
            <div className={styles.social}></div>
            <div className={styles.social}></div>
          </div>
          <div className="" style={{ color: "#fff" }}>
            © Photo, Inc. 2019. We love our users!
          </div>
        </div>
      </div>
    </div>
  );
}
