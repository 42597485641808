import { Space } from 'antd'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { ProjectViewContainer, Container } from '../project-view'
import SearchPanel, { Query } from '../../components/SearchPanel'
import TagsGroup from '../../components/TagsGroup'
import { BottomLine } from '../project-view'
import ProjectTabs from '../../components/ProjectTabs'
import useCopyWriter from "../../hooks/use-copywriter";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { spliceProject, useConditionName, useProjectSearchParams } from './util'
import * as _ from 'lodash'
import { useResetURLParam } from '../../hooks/useURLParams'
import { findOption } from '../../components/SearchPanel/util'

const ProjectQuery = () => {
  const navigate = useNavigate()
  const t = useCopyWriter()
  const dataAll = Object.values(t('projectQuery'))
  const [param, setParam] = useProjectSearchParams()
  const [searchParam, setSearchParam] = useSearchParams()
  const resetURL = useResetURLParam()
  const allProject = spliceProject(t('projectQuery.projectAllUtil'))
  const [projectList, setProjectList] = useState<any[]>(allProject)
  const [query, setQuery] = useState<any[]>([])
  const getConditionName = useConditionName()

  const seeDetails = useCallback((id: string): string => {
    return `/projectDetails?projectId=${id}&queryGroup=${encodeURI(JSON.stringify(query.map(item => item.id)))}`
  }, [query])

  useEffect(() => resetURL(), [])

  useEffect(() => {
    const tagGroup: any[] = []
    Object.values(param).forEach(query => {
      tagGroup.push(findOption(query, dataAll))
    })
    setQuery(tagGroup)
    if (!tagGroup.length) {
      setProjectList(allProject)
      return
    }
    const label = tagGroup.length === 1
    ? tagGroup[0]['label'] : `${tagGroup.map(item => item.label).join(' / ')}`
    setProjectList([[{...tagGroup.at(-1), label }]])
  }, [param])

  const deleteTag = useCallback((id: string) => {
    const cloneQuery: Partial<Query> | any = _.cloneDeep(param)
    Object.keys(cloneQuery).forEach((key: any) => {
      if (cloneQuery[key] == id) {
        delete cloneQuery[key]
      }
    })
    // console.log(cloneQuery)
    setSearchParam(cloneQuery)
  }, [param])
 
  return <ProjectViewContainer>
    <Container>
      <SearchPanel mainDep={'all'} param={param} setParam={setParam} showSelect={['all', 'project', 'section', 'subProject']} />
      <TagsGroup group={query} deleteTag={deleteTag} />
      <BottomLine style={{marginTop: '10px'}} />
      {
        projectList.map((projectGroup, index) => <ProjectTabs prefixs="总包" sub={false} key={index} tabs={projectGroup} seeDetails={seeDetails}/>)
      }
    </Container>
  </ProjectViewContainer>
}

export default ProjectQuery