import React, { ReactElement, useCallback } from 'react'
import styled from 'styled-components';
import { Card, BottomLine } from '../../pages/project-view'
import { Row, Col } from 'antd'
import Pagination from "rc-pagination";
import leftArrow from '../../assets/img/project-view/left-arrow.png'
import rightArrow from '../../assets/img/project-view/right-arrow.png'
import './index.less'

interface Columns {
  title?: string | ReactElement;
  dataIndex?: string;
  span: number;
  render?(arg?: any, project?: any): Element | ReactElement;
}

interface ProjectTableProps {
  pagination?: boolean;
  rowKey?(...[arg]: any[]): number | string;
  columns: Columns[];
  dataSource: any[];
  firstSpan?: number;
}

const ProjectTable = (props: ProjectTableProps) => {
  const prevIcon = useCallback(() => {
    return <Circle>
      <img src={leftArrow} alt="" />
    </Circle>
  }, [])
  const nextIcon = useCallback(() => {
    return <Circle>
      <img src={rightArrow} alt="" />
    </Circle>
  }, [])

  return <Card style={{height: 'auto', background: 'none', paddingBottom: '20px'}}>
    <Row style={{padding: '16px 45px'}} justify={'space-between'} gutter={10}>
      {/* <TableHeaderCol span={props.firstSpan || 3}>
        <span>编号</span>
      </TableHeaderCol> */}
      {
        props.columns?.map((item: any, index: number) =>
          <TableHeaderCol key={index} span={item.span || 3}>
            {
              typeof item.title === 'string' ? item.title : <>{item.title}</>
            }
          </TableHeaderCol>
        )
      }
    </Row>
    <BottomLine />
    <div className='table-list'>
      {
        props.dataSource?.map((item: any, index: number) => (
          <Row style={{padding: '10px 45px'}} key={index} justify={'space-between'}>
            {/* <TableColumnsCol span={props.firstSpan || 3} key={0}>{index + 1}</TableColumnsCol> */}
            {
              props.columns?.map((column, ci) => {
                return column.render
                  ? <TableColumnsCol span={column.span || 3} key={ci}>{column.render(column.dataIndex ? item[column.dataIndex] : null, item)}</TableColumnsCol>
                  : <TableColumnsCol span={column.span || 3} key={ci}>{column.dataIndex && item[column.dataIndex]}</TableColumnsCol>
              })
            }
          </Row>
        ))
      }

      <PaginationWrapper>
        <Pagination prevIcon={prevIcon} nextIcon={nextIcon} total={100} />
      </PaginationWrapper>
    </div>
  </Card>
}

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3259D2;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableHeaderCol = styled(Col)`
  color: #FFFCFC!important;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
`

const TableColumnsCol = styled(Col)`
  font-size: 18px;
  color: #FFFCFC!important;
  text-align: center;
`

export default ProjectTable
