import React, { useLayoutEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import cn from 'classnames';
import ReactEcharts from "echarts-for-react"
import * as echarts from 'echarts';
import { getMonitoringAState } from '../../components/large-screen-data';
import '@lls/react-light-calendar/dist/index.css'
import Calendar from "./Calendar";
import { LargeScreenTable } from '../large-screen2';
import useCopyWriter from '../../hooks/use-copywriter';

export const columns1 = [
  {
    title: '编号',
    dataIndex: 'id',
    width: '10%',
    textAlign: 'center'
  },
  {
    title: '项目名称',
    dataIndex: 'name',
    width: '15%',
    textAlign: 'center'
  },
  {
    title: '碳排放（当前值/设计值/碳排比例）',
    dataIndex: 'currentValue',
    width: '50%',
    textAlign: 'center'
  },
  {
    title: '状态',
    dataIndex: 'options',
    width: '17%',
    textAlign: 'center',
    render: (text: string, record: any) => (
      <button className={cn(styles.tableButton,
        { [styles.greenBtn]: Number(record.status) === 1, [styles.redBtn]: Number(record.status) === 0 })
      }>
        {getMonitoringAState(Number(record.status))}
      </button>
    ),
  }
];
function LargeScreen() {
  const t = useCopyWriter();
  const [echartRender, setEchartsRender] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setEchartsRender(true)
    }, 200)
  }, [])

  const dataSource = useMemo(()=>{
    const data= t("home.monitoringA");
    if(data){
      return data;
    }
    return []
  },[])

  const option = {
    xAxis: {
      type: 'category',
      data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      axisTick: {
        alignWithLabel: false
      }
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [120, 200, 350, 80, 470, 110, 630, 170, 350, 20, 140, 10],
        type: 'bar',
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#5463FA' },
            { offset: 0.2, color: '#5463FA' },
            { offset: 1, color: '#752ED8' }
          ])
        },
      }
    ]
  };

  const nameStyle = {
    color: '#fff',
    fontSize: '18px',
    textBorderWidth: 0,
    textBorderColor: 'rgba(0,0,0,0)'
  }

  var datas2 = [
    { value: 50, name: '50%', label: nameStyle },
    { value: 10, name: '15%', label: nameStyle },
    { value: 5, name: '5%', label: nameStyle },
    { value: 30, name: '5%', label: nameStyle },
    { value: 5, name: '25%', label: nameStyle }
  ]

  const roundChartsData = {
    tooltip: {
      trigger: false//设置为false,点击环形图，不出现弹出层
    },
    legend: {
      show: false,
    },
    color: ['#61DDAA', '#5556E3', '#AE30DA', '#5889F0', '#D01864'],
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        startAngle: -40,//圆环的起始方向
        label: {
          show: false,
          position: 'outside',
          formatter: '{name|{b}}\n{time|{c} 小时}',
          normal: {
            show: true,
            length: 25,             // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
            length2: 50,
            formatter: (params: any) => {
              return params.name
            },
          }
        },

        emphasis: {
          show: false
        },
        labelLine: {
          show: true,
          normal: {
            show: true
          }
        },
        data: datas2
      }
    ]
  };

  const lineChartData = {
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['第一季度', '第二季度', '第三季度', '第四季度', '第五季度']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [400, 800, 700, 540, 300],
        type: 'line',
        areaStyle: {}
      }
    ]
  };

  return (
    <>
      <div className={styles.largeScreen}>
        <div className={styles.content}>
          <div className={styles.menuDividingLine}></div>
          <div className={cn("container", styles.charts)}>
            <LargeScreenTitle>公共工程建设碳排放计算管理平台实时监控大屏</LargeScreenTitle>
            <div className={styles.largeScreenData}>
              <div className={styles.carbonRecord}>
                {/* 月 */}
                <div className={styles.monthRecord}>
                  <ChartsTitle>月碳排放统计</ChartsTitle>
                  <div className={styles.dateArea}>
                    <Calendar />
                    <button className={styles.detailBtn}>详情</button>
                  </div>
                </div>
                {/* 年 */}
                <div className={styles.yearRecord}>
                  <ChartsTitle>年度碳排放统计</ChartsTitle>
                  <div className={styles.yearEchartRecord}>
                    <span className={styles.yearWrite}>
                      {`<  2021年  >`}
                    </span>
                    <div className={styles.chartsContent}>
                      {echartRender && <ReactEcharts
                        className={styles.yearBarData}
                        option={option}
                      />}
                      <span className={styles.monthWrite}>月</span>
                    </div>
                  </div>

                </div>
              </div>
              <div className={styles.carbonStateRecord}>
                <div className={styles.dotsCharts}>
                  <div className={styles.introduce}>
                    <span className={styles.dotChartsTitle}>散点图说明:</span>
                    <OptionIntroduce dotColor="redDot" writeDes='碳排放超标' />
                    <OptionIntroduce dotColor="yellowDot" writeDes='碳排放正常' />
                  </div>
                </div>
                <div className={styles.roundCharts}>
                  <ChartsTitle>当日碳排放统计</ChartsTitle>
                  <div className={styles.chartsArea}>
                    <div className={styles.optionsDes}>
                      <OptionIntroduce dotColor="greenDot" writeDes='碳排放正常' />
                      <OptionIntroduce dotColor="blueDot" writeDes='超标10%以内' />
                      <OptionIntroduce dotColor="purpleDot" writeDes='超标10%-20%' />
                      <OptionIntroduce dotColor="pinkDot" writeDes='超标20%-50%' />
                      <OptionIntroduce dotColor="redDot" writeDes='超标50%' />
                    </div>
                    <div className={styles.roundData}>
                     {
                        echartRender && <ReactEcharts
                            className={styles.roundDataYearRecord}
                            option={roundChartsData}
                          />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.carbonData}>
                <div className={styles.carbonTableShow}>
                  <ChartsTitle>项目碳排放统计</ChartsTitle>
                  <div className={styles.table}>
                      <LargeScreenTable
                            dataSource={dataSource}
                            columns={columns1}
                            tHeadStyle={{fontSize:'12px'}}
                            tBodyStyle={{fontSize:'12px'}}
                            tableClassName={styles.largeScreenTable}
                            divideLineClassName={styles.divideLine}
                            tHeadClassName={styles.tHead}
                            tRowClassName={styles.tRow}
                        />
                  </div>
                </div>
                <div className={styles.barCharts}>
                  <ChartsTitle>季度碳排放统计</ChartsTitle>
                  <div className={styles.lineCharts}>
                   {echartRender && <ReactEcharts option={lineChartData} className={styles.lineChartData} />}
                    <div className={styles.des1}>
                        <span className={styles.exclamationMark}></span>
                        <span>碳排放超标</span>
                    </div>
                    <div className={styles.des2}>
                      第二季度标段碳排放量超标
                      <span className={styles.percentage}>20%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LargeScreen;

interface OptionProps {
  dotColor: string;
  writeDes: string;
}
function OptionIntroduce(props: OptionProps) {
  const { dotColor, writeDes } = props;
  return (
    <div className={styles.options}>
      <span className={styles[dotColor]}></span>
      {writeDes}
    </div>
  )
}

interface TitleProps {
  children: string;
  background?: string;
}
export function LargeScreenTitle({ children }: TitleProps) {
  return (
    <>
      <h2 className={styles.title}>{children}</h2>
      <div className={styles.rowLine}></div>
    </>
  )
}


export function ChartsTitle(props: TitleProps) {
  const { children, background } = props;
  return (
    <h3 style={{ background: background || '' }}>
      <span className={styles.titleLine}></span>
      {children}
    </h3>
  )
}