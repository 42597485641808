import Footer from "../components/footer";
import Header from "../components/header";
import styles from "./index.module.less";

interface IProps {
  children: React.ReactChildren | React.ReactChild;
}

export default function Layout({ children }: IProps) {
  return (
    <div className={styles.layout}>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
