import { useMemo } from 'react';
export const usePieData = (value: any) => {

  return useMemo(() => ({
    tooltip: {
      trigger: 'item'
    },
    legend: {
      show: false
    },
    color: ['#D01864', '#F9B02B', '#3259D2'],
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: [
          { value: value.transportRatio, name: '运输碳排放量' },
          { value: value.workRatio, name: '施工碳排放量' },
          { value: value.buildRatio, name: '建材碳排放量' },        
        ],
        labelLine: {
          show: true
        },
        label: {
          textStyle: {
            fontSize: 13,
            color: '#fff'
          },
          formatter: '{b}{c}%'
        },
      }
    ]
  }), [value])
}

export const useFoldData = (design: any[], actually: any[]) => {
  const overSeries: any[] = []
  actually.forEach((item, index) => {
    if (item > design[index]) {
      overSeries.push({value: [index, item], symbolSize: 14})
    }
  })
  return useMemo(() => (
    {
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
      },
      yAxis: {
        type: 'value'
      },
      color: ['#59CBA3', '#BF185F'],
      legend: {
        orient: 'horizontal',
        left: '0%',
        icon: 'circle',
        textStyle: {
          fontSize: 18,
          color: '#fff',
          // lineHeight: 10,
        },
        data: ['设计碳排放量趋势', '实际碳排放量趋势']
      },
      series: [
        {
          type: 'line',
          // data: [12, 1,  2,  3, 4, 5, 6, 7, 11, 10, 9, 10]
          data: design,
          itemStyle: {
            normal: {
              color: '#F2F832'
            }
          },
          lineStyle:{
            normal:{
                color:'#59CBA3',//折线的颜色
                width:'2'//折线粗细
            }
          },
          symbol:'circle',//拐点设置为实心
          symbolSize:6,//拐点大小
          name: '设计碳排放量趋势'
        },
        {
          type: 'line',
          data: actually,
          lineStyle: {
            normal: {
              color: '#BF185F',
              width: '2'
            }
          },
          itemStyle: {
            normal: {
              color: function(context: any) {
                // [dataIndex]
                if ([0, 2, 6, 7].includes(context.dataIndex)) {
                  return '#fff'
                } else {
                  return '#F2F832'
                }
              }
            }
          },
          symbol:'circle',//拐点设置为实心
          symbolSize:6,//拐点大小
          name: '实际碳排放量趋势'
        },
        {
          type: 'effectScatter',
          coordinateSystem: 'cartesian2d',
          data: overSeries,
          itemStyle: {
            color: '#D01864',
            opacity: 1
          },
          rippleEffect: {
            color: '#D01864',
            scale: 3
          },
          tooltip: {
            show: false
          }
        }
      ]
    }
  ), [design, actually, overSeries])
}