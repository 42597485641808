import { IdSelect } from '../idSelection'
import React, { useEffect, useState } from 'react'
import useCopyWriter from "../../hooks/use-copywriter";
import { Select, SelectProps } from 'antd'
import type { SelectOptions } from '../SearchPanel'

const Option = Select.Option

interface SelectionProps extends SelectProps {
  value?: null | undefined | any;
  onChange?: (...value: any[]) => void;
  options: SelectOptions[] | [];
  size?: 'large' | 'small' | 'middle'
}

const ProjectSelect = (props: React.ComponentProps<typeof IdSelect>) => {
  const t = useCopyWriter();
  const [project, setProject] = useState([])
  const {options}=props;
  useEffect(() => {
    //data传入select 的option
    const data =options ?? t('projectView.projectType')
    setProject(data ? data : [{
      name: '预警',
      value: '预警'
    }, {
      name: '正常',
      value: '正常'
    }])
  }, [])
  return <IdSelect placeholder={'项目类型'} options={project} {...props} />
}

export const MonthSelect = (props: React.ComponentProps<typeof IdSelect>) => {
  const t  = useCopyWriter()
  const monthSelectOption = t('projectView.monthList')
  const [project, setProject] = useState<any[]>([])
  const {options}=props;

  useEffect(() => {
    const mockData = []
    for (let i = 0; i < monthSelectOption.length; i++) {
      mockData.push({
        name: monthSelectOption[i],
        value: i + 1
      })
    }
    setProject(options || mockData)
  }, [])

  return <IdSelect placeholder={'月度选择'} options={project} {...props} />
}

export const QuerySelect = (props: SelectionProps) => {
  return(
    <Select
      className={'transparent-select'}
      value={props.value && Number(props.value)}
      dropdownClassName={'transparent-dropdown'}
      size={'large'}
      // onChange={props.onChange}
      placeholder={'请选择'}
      style={{width: '200px'}}
      onChange={props.onChange}
      disabled={props.disabled}
    >
      {
        props.options?.map((option: SelectOptions, index: number) => (
          <Option key={option.id} value={option.value}>
            {option.label}
          </Option>
        ))
      }
    </Select>
  )
}

export default ProjectSelect;
