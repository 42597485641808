import { useNavigate } from "react-router-dom";
import styles from "./index.module.less";

interface IProps {
  title: string;
  cover: string;
  link: string;
}

export default function ProjectCard({ title, cover, link }: IProps) {
  const navigate=useNavigate()
  return (
    <div className={styles.project}>
      <img src={cover} alt="" className={styles.cover} />
      <div className={styles.title}>{title}</div>
      <div className={styles.links}>
        <button className={styles.carbonDetailBtn} onClick={onSeeDetail}>碳排放详情</button>
        <button className={styles.projectDetailBtn} onClick={onSeeDetail}>项目详情</button>
      </div>
    </div>
  );

  function onSeeDetail(){
      //瞎跳转，大屏数据和项目检索毫无关联，跳转就要一个效果,就这吧
      const id=1;
      const query='%5B%5D';
      navigate(`/projectDetails?projectId=${id}&queryGroup=${query}`)
  }
}
