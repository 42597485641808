import useCopyWriter from "../../hooks/use-copywriter";
import { ProjectTypeCard } from '../../components/project-type-card'
import { Row, Col, Space, Select, } from 'antd'
import iconWarn from '../../assets/img/project-view/warn.png'
import iconNormal from '../../assets/img/project-view/normal.png'
import iconHistory from '../../assets/img/project-view/history.png'
import backgroundImg from '../../assets/img/project-view/itemBackground.png'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react'
import ProjectSelect, { MonthSelect } from '../../components/project-select'
import { Button } from '../../components/project-type-card'
import React, { useState, useCallback, useEffect, useMemo } from "react"
import { optionsMock, optionMonthMock } from './data'
import { LargeScreenTable } from "../large-screen2";
import styles from './index.module.less';
import Pagination from "rc-pagination";
import leftArrow from '../../assets/img/project-view/left-arrow.png'
import rightArrow from '../../assets/img/project-view/right-arrow.png'
import { useInView } from 'react-intersection-observer';



const { Option } = Select;

const TotalPackageStatistics: React.FC = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })
  const t = useCopyWriter();
  const dataSourceMock: any[] = t('totalView.totalList').slice(0, 10)
  const totalView = t('totalView');
  const totalType = totalView.totalType;
  const materialsList = totalView.totalList;
  const [sortUp, setSortUp] = useState<boolean>(false);


  const [dataSource, setDataSource] = useState(dataSourceMock);
  const [dataList, setDataList] = useState<any[]>();
  const [saveDataList, setSaveDataList] = useState<any[]>();
  const [monthTitle,setMonthTitle]=useState<any>();



  const filterDataSource = useCallback((status) => {
    if (!inView) {
      const el = document.getElementById('table-wrapper-scroll')
      el?.scrollIntoView()
    }
    setDataSource(dataSourceMock.filter(item => item.status === status))
  }, [inView])

  const prevIcon = useCallback(() => {
    return <Circle>
      <img src={leftArrow} alt="" />
    </Circle>
  }, [])
  const nextIcon = useCallback(() => {
    return <Circle>
      <img src={rightArrow} alt="" />
    </Circle>
  }, [])

 //升序
 useMemo(() => {
  const dataLists = materialsList.slice(0, 10);
  setSaveDataList(dataLists);
  const result = dataLists.sort(function (a: any, b: any) {
    return Number(a.type)- Number(b.type)
  });
  setDataList(result);
}, []);

const onSort=useCallback(()=>{
  const dataLists = materialsList.slice(0, 10);
  setSaveDataList(dataLists);
  if(sortUp){
    //升序
    const result = dataLists.sort(function (a: any, b: any) {
      return Number(a.type)- Number(b.type)
    });
    setDataList(result);
    setSortUp(false);
  }else{
    const downResult = dataLists.sort(function (a: any, b: any) {
      return Number(b.type)-Number(a.type);
    });
    setDataList(downResult);
    setSortUp(true);
  }
},[sortUp])

  const columns1 =useMemo(()=>{
    return  [
      {
        title: '总包名称',
        dataIndex: 'name',
        width: '15%',
      },
      {
        title: '总包产生总碳排量（t）',
        dataIndex: 'totalcarbon',
        width: '20%'
      },
      {
        title: '总包规划总碳排量（t)',
        dataIndex: 'designcarbon',
        width: '20%'
      },
      {
        title: '总包碳排放等级',
        dataIndex: 'type',
        width: '200px',
        textAlign: 'center',
        sortUp:sortUp,
        onClick:onSort
      },
      {
        title: '总包项目碳排放达标率',
        dataIndex: 'percent',
        width: '300px',
        textAlign: 'center'
      }
    ];

  },[sortUp])

  const [options, setOptions] = useState(optionsMock([15, 35, 30, 25]))
  const [optionMonth, setOptionsMonth] = useState(optionMonthMock([12, 1, 2, 3, 4, 5, 6, 7, 11, 10, 9, 10], [12, 9, 12, 3, 6, 7, 8, 9, 3, 5, 7, 10]))

  const [monthValue, setMonthValue] = useState(null)
  const [projectType, setProjectType] = useState(null)
  const [tableSearchType, setTableSearchType] = useState(null);

  useEffect(() => {
    const monthV=[...totalView.monthList];
    const result:any=monthV.map((item,index)=>{
        return {id:index,name:item}
      })
    // setMonthValue(result);

    if (projectType === '1') {
      setOptions(optionsMock([24, 10, 5, 59]))
    } else if (projectType === '2') {
      setOptions(optionsMock([59, 15, 10, 22]))
    } else {

    }
  }, [projectType])


  const changeSelectMonth = useCallback((month) => {
    const arg1 = [], arg2 = []
    let i = 12
    while (i--) {
      arg1.push(
        Math.floor(Math.random() * (12 - 1 + 1)) + 1
      )
      arg2.push(
        Math.floor(Math.random() * (12 - 1 + 1)) + 1
      )
    }
    setMonthValue(month)
    setOptionsMonth(optionMonthMock(arg1, arg2))
  }, [])

  const seeDeatils = useCallback(() => {
    if (tableSearchType === '1') {
      setDataSource(dataSourceMock.filter((item, index) => index % 2 === 0))
    } else if (tableSearchType === '2') {
      setDataSource(dataSourceMock.filter((item, index) => index % 2 !== 0))
    } else {
      setDataSource(dataSourceMock)
    }
  }, [tableSearchType])

  function handleChange(value: number, option: any) {
    if (saveDataList) {
      const result = saveDataList.filter((item: any) => item.project == option.children);
      setDataList(result)
    }

  }


  return <ProjectViewContainer>
    <Container>
      <Title>
        总包总览
      </Title>

      <Row style={{ 'marginTop': '82px' }} justify={'space-between'}>
        <Col span={9}>
          <Card>
            <Header>
              <div className="left_text">
                <span>在执行总包碳排放总览</span>
              </div>
              <div className="control">
                <ProjectSelect placeholder="总包类型" onChange={(value: any) => setProjectType(value)} value={projectType} options={totalType}/>
              </div>
            </Header>
            <BottomLine />
            <div className="content">
              <ReactECharts
                option={options}
                style={{ height: 200 }}
              />
            </div>
          </Card>
        </Col>
        <Col span={14}>
          <Card>
            <Header>
              <div className="left_txt">
                <span>在执行总包碳排放数据统计</span>
              </div>
              <div className="control">
                <MonthSelect onChange={changeSelectMonth} value={monthValue}/>
                <div className="line"></div>
              </div>
            </Header>
            <BottomLine />
            <div style={{ marginTop: '11px' }}>
              <EchartsTitle className="info">
                <div>实际碳排放：xxx%</div>
                <div style={{ marginLeft: '30px' }}>预测碳排放：xxx%</div>
              </EchartsTitle>
              <ReactECharts
                option={optionMonth}
                style={{ height: 250 }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/* <Row gutter={35} style={{
        marginTop: '38px'
      }} justify="space-between" id={'table-wrapper-scroll'}>
        <Col span={8}>
          <ProjectTypeCard
            icon={iconWarn}
            title={'碳排放异常项目'}
            statusDes={'预警及超标'}
            id={'217'}
            background={backgroundImg}
            theme={'#D01864'}
            viewDetail={() => filterDataSource(0)}
          />
        </Col>
        <Col span={8}>
          <ProjectTypeCard
            icon={iconNormal}
            title={'在执行项目'}
            statusDes={'状态正常'}
            id={'312'}
            background={backgroundImg}
            theme={'#3259D2'}
            viewDetail={() => filterDataSource(1)}
          />
        </Col>
        <Col span={8}>
          <ProjectTypeCard
            icon={iconHistory}
            title={'历史项目'}
            statusDes={'已监控工程'}
            id={'415'}
            background={backgroundImg}
            theme={'#F9B02B'}
          />
        </Col>
      </Row> */}


      <div className="project_details" style={{ marginTop: '80px' }}>
        <Row>
          <Col>
            <Title>总包详情</Title>
          </Col>
          <Col style={{ paddingTop: '12.25px', marginLeft: '27px' }}>
            <Select style={{ width: 120 }} onChange={handleChange} placeholder="所属的项目">
              {
                totalType.map((item: any, index: number) => <Option key={index} value={item.value}>{item.name}</Option>)
              }
            </Select>
          </Col>
          <Col style={{ marginLeft: '10px' }}>
            <Button onClick={seeDeatils} color={'#fff'}>
              查看详情
            </Button>
          </Col>
        </Row>

        <Card ref={ref} style={{ marginTop: '33px', padding: '0 50px', overflow: 'hidden', height: 'auto' }}>
          {
            dataList && dataList?.length > 0 ? (
              <LargeScreenTable
                dataSource={dataList}
                columns={columns1}
                tHeadClassName={styles.tHeader}
                tableClassName={styles.tBodys}
                divideLineClassName={styles.divideLine}
              />
            ) : <h1 className={styles.noData}>没有该种类的数据</h1>
          }
          <div className={styles.pagination}>
            <PaginationWrapper>
              <Pagination prevIcon={prevIcon} nextIcon={nextIcon} total={100} />
            </PaginationWrapper>
          </div>
        </Card>
      </div>
    </Container>
  </ProjectViewContainer>
}
const PaginationWrapper = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3259D2;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TextEllipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  transition: all .2s;
  box-sizing: border-box;
  white-space: nowrap;
`

const SortIconDown = styled.img`
  position: relative;
  cursor: pointer;
  margin-left: 10px;
`

const SortIconUp = styled.img`
  position: relative;
  margin-left: 10px;
  cursor: pointer;
  transform: rotate(180deg);
`

const EchartsTitle = styled(Row)`
  font-weight: 500;
  position: absolute;
  right: 0;
  left: 40px;
  font-size: 20px;
  /* padding-left: 40px; */
  color: #ffffff;
  line-height: 22px;
`

export const BottomLine = styled.div`
    bottom: 2px;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #33388E 0%, #FCFCFF 51.56%, #32388D 100%);
    border-radius: 8px;
    background-size: 100% 100%;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 38px;
  color: #fff;
  font-weight: 500;
  position: relative;
  font-size: 24px;
  .left_tet {
    flex: 1;
  }
  .right-control {
    flex: 0 0 100px;
  }
`

export const Card = styled.div`
  background: linear-gradient(175deg, #3851A9 0%, rgba(47, 21, 101, 0) 100%) no-repeat center;
  background-size: 100% 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 348px;
  box-sizing: border-box;
  .content {
    padding: 60px 0;
  }
`

const ProjectViewContainer = styled.div`
  padding: 0 50px;
  padding-top: 102px;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  background: linear-gradient(
    90deg,
    #213e61 5.74%,
    #093b83 48.45%,
    #171c70 98.97%
  );
  .title {
    font-size: 35px;
    color: #ffffff;
  }
`
const Container = styled.div`
  margin: 0 auto;
  max-width: 80%;
  @media (max-width: 1680px) {
    max-width: 1580px;
  }
`

const Title = styled.span`
  font-size: 35px;
  color: #ffffff;
`

export default TotalPackageStatistics;