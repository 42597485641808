import { Space, DatePicker } from 'antd'
import { ReactChild, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { QuerySelect } from '../project-select'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Button } from '../../components/project-type-card'
import useCopyWriter, { useFindParentById } from "../../hooks/use-copywriter";
import { findOption, filterChildren } from './util'
import useProjectAllItem, { getGroupSubFromCurGroup } from '../../hooks/useProjectAllItem';
import { useResetURLParam } from '../../hooks/useURLParams';

const { RangePicker } = DatePicker

export interface SelectOptions {
  id: string;
  parent?: string;
  label: string;
  value: string
}

export interface Query {
  project: string;
  section: string;
  subProject: string;
  construct: string;
  component: string;
  process: string;
  material: string;
  supplier: string;
}

const SearchPanel = ({ param, setParam, showSelect = ['project', 'section','subProject', 'construct', 'component', 'process', 'material', 'supplier'], showDatePick = false, mainDep}: {
  param: Partial<Query> | any,
  setParam: any,
  showSelect?: any[],
  showDatePick?: boolean,
  mainDep: 'project' | 'process' | 'component' | 'material' | 'all'
}) => {
  const t = useCopyWriter();
  const resetURL = useResetURLParam()
  // options
  const [project, setProject] = useState<SelectOptions[] | any[]>(t('projectQuery.projectName'))
  const [projectSection, setProjectSection] = useState<SelectOptions[] | any[]>(t('projectQuery.projectSection'))
  const [subProject, setSubProject] = useState<SelectOptions[] | any[]>(t('projectQuery.subProject'))
  const [projectConstruct, setProjectConstruct] = useState<SelectOptions[] | any[]>(t('projectQuery.projectConstruct'))
  const [projectComponent, setProjectComponent] = useState<SelectOptions[] | any[]>(t('projectQuery.projectComponent'))
  const [projectProcess, setProjectProcess] = useState<SelectOptions[] | any[]>(t('projectQuery.projectProcess'))
  const [projectMaterial, setProjectMaterial] = useState<SelectOptions[] | any[]>(t('projectQuery.projectMaterial'))
  const [projectSupplier, setProjectSupplier] = useState<SelectOptions[] | any[]>(t('projectQuery.projectSupplier'))
  const [allUtil, setAllUtil] = useState<SelectOptions[] | any[]>(t('projectQuery.projectAllUtil'))
  // value
  const [rangDate, setRangeDate] = useState(null)
  const setRangeHandle = (range: any) => {
    setRangeDate(range)
  }
  // dep:projectId filter children item
  const dataAll = Object.values(t('projectQuery'))
  const mainDepValue = useProjectAllItem(param[mainDep])
  const projectMain = useFindParentById(param[mainDep], 0)
  useEffect(() => {
    // setQueryURLParams(findGroup([param.project].filter(item => item !== null)))

    if (mainDep === 'project') {
      setProjectSection(mainDepValue.sectionMockList)
      setSubProject(mainDepValue.subProjectMockList)
    } else {
      if (!projectMain) return
      setProject([projectMain])
      setProjectSection(getGroupSubFromCurGroup(
          [projectMain],
          dataAll
        )
      )
      setSubProject(getGroupSubFromCurGroup(
          projectSection,
          dataAll
        )
      )
    }
    // setProjectSection(projectData.sectionMockList)
    // setSubProject(projectData.subProjectMockList)
    // setProjectConstruct(projectData.constructMockList)
    // setProjectComponent(projectData.componentMockList)
    // setProjectProcess(projectData.processMockList)
    // setProjectMaterial(projectData.materialMockList)
    // setProjectSupplier(projectData.supplierMockList)
  }, [mainDepValue])

  const resetAll = useCallback(() => {
    setRangeDate(null)
    setProject(t('projectQuery.projectName'))
    setProjectSection(t('projectQuery.projectSection'))
    setSubProject(t('projectQuery.subProject'))
    setProjectConstruct(t('projectQuery.projectConstruct'))
    setProjectComponent(t('projectQuery.projectComponent'))
    setProjectProcess(t('projectQuery.projectProcess'))
    setProjectMaterial(t('projectQuery.projectMaterial'))
    setProjectSupplier(t('projectQuery.projectSupplier'))
    resetURL()
  }, [])

  const findGroup = (group: any[]): any[] => group.map(id => findOption(id, dataAll))
  // children ->
  const generatorChild = (option: SelectOptions, setOption: any) => {
    const parentCode = option.value
    if (setOption) {
      setOption(filterChildren(parentCode, dataAll))
    }
  }

  // <- parent value chain
  // const generatorParentValue = (option: SelectOptions, setValueChain: any[]) => {
  //   const parentCode = option.parent
  //   if (!parentCode) return
  //   const parentOption = findOption(parentCode, dataAll)
  //   const executeIndex = setValueChain.length - 1
  //   // setValue
  //   setValueChain[executeIndex](Number(parentOption.id))
  //   setValueChain.pop()
  //   generatorParentValue(parentOption, setValueChain)
  // }

  // <- parent Options chain
  /**
   * 
   * @param optionChain SelectOptions[] 正序，从项目名开始
   * @param setOptionsChain 从项目标注开始
   */
  const generatorParentOptions = (optionChain: string[], setOptionsChain: any[]) => {
    if (!setOptionsChain.length) return
    const parentCode: string = optionChain[0] as string
    const nextOptions = filterChildren(parentCode, dataAll)
    const generatorNestOptions = setOptionsChain[0]

    generatorNestOptions(nextOptions)
    optionChain.shift()
    setOptionsChain.shift()
    generatorParentOptions(optionChain, setOptionsChain) //eslint-disable-line
  }


  const setProjectValueHandle = (value: string, option: any) => {
    resetURL()
    setParam({project: value})
  }
  
  const setProjectSectionHandle = useCallback((value, option) => {
    setParam({...param, section: value})
    generatorChild(option, setSubProject)
  }, [param])

  const setSubProjectValueHandle = useCallback((value, option) => {
    // setSubProjectValue(value)
    setParam({...param, subProject: value})
    generatorChild(option, setProjectConstruct)
    // generatorParentValue(option, [setProjectValue, setSectionValue])
  }, [param])

  const setConstructValueHandle = useCallback((value, option) => {
    // setConstructValue(value)
    setParam({...param, construct: value})
    generatorChild(option, setProjectComponent)
    // generatorParentValue(option, [setProjectValue, setSectionValue, setSubProjectValue])
  }, [param])

  const setComponentValueHandle = useCallback((value, option) => {
    // setComponentValue(value)
    setParam({...param, component: value})

    generatorChild(option, setProjectProcess)
    // generatorParentValue(option, [setProjectValue, setSectionValue, setSubProjectValue, setConstructValue]) 
  }, [param])

  const setProcessValueHandle = useCallback((value, option) => {
    // setProcessValue(value)
    setParam({...param, process: value})

    generatorChild(option, setProjectMaterial)
    // generatorParentValue(option, [setProjectValue, setSectionValue, setSubProjectValue, setConstructValue, setComponentValue])
  }, [param])

  const setMaterialValueHandle = useCallback((value, option) => {
    // setMaterialValue(value)
    setParam({...param, material: value})

    generatorChild(option, setProjectSupplier)
    // generatorParentValue(option, [setProjectValue, setSectionValue, setSubProjectValue, setConstructValue, setComponentValue, setProcessValue])
  }, [param])

  const setSupplierValueHandle = useCallback((value, option) => {
    // setSupplierValue(value)
    setParam({...param, supplier: value})
    // generatorParentValue(option, [setProjectValue, setSectionValue, setSubProjectValue, setConstructValue, setComponentValue, setProcessValue, setMaterialValue])
  }, [param])

  const setAllUtilHandle = useCallback((value, option) => {
    setParam({...param, all: value})
  }, [param])

  const mainDepSelected = useMemo(() => !!param[mainDep], [param, mainDep])

  return <>
    <Space size={50}>
      {
        showSelect.includes('component') ?
        <TitleInput label='构件名称'>
        <QuerySelect disabled={mainDep!=='component' && !mainDepSelected} options={projectComponent} value={param.component} onChange={setComponentValueHandle} />
      </TitleInput>: null
      }
      {
        showSelect.includes('all') ?
        <TitleInput label='总包名称'>
          <QuerySelect disabled={mainDep!=='all' && !mainDepSelected} options={allUtil} value={param.all} onChange={setAllUtilHandle} />
        </TitleInput>: null
      }
      {
        showSelect.includes('process') ?
        <TitleInput label='工序'>
        <QuerySelect disabled={mainDep!=='process' && !mainDepSelected} options={projectProcess} value={param.process} onChange={setProcessValueHandle} />
      </TitleInput> : null
      }
      {
        showSelect.includes('material') ?
        <TitleInput label='材料'>
        <QuerySelect disabled={mainDep!=='material' && !mainDepSelected} options={projectMaterial} value={param.material} onChange={setMaterialValueHandle} />
      </TitleInput> : null
      }
    </Space>

    {
      showSelect.includes('component') ||
      showSelect.includes('process') ||
      showSelect.includes('material') ||
      showSelect.includes('all') ? <br /> : null
    }

    <Space size={0} wrap>
      {
        showSelect.includes('project') ?
        <TitleInput label='项目名称'>
          <QuerySelect disabled={mainDep !== 'project' && !mainDepSelected} options={project} value={param.project} onChange={setProjectValueHandle} />
        </TitleInput> : null
      }
      <div style={{width: '50px'}}></div>
      {
        showDatePick ?
          <TitleInput label='项目周期'>
            <RangePicker clearIcon={false} className={'transparent-rangePicker'} onChange={setRangeHandle} value={rangDate} locale={locale} size={'large'} />
          </TitleInput>
       : null
      }
    </Space>
    {
      showDatePick ? <br /> : null
    }
    <Space size={50} wrap style={{verticalAlign: 'top'}}> 
      {/* <div style={{width: '5px'}}></div> */}
      {
        showSelect.includes('section') ? 
        <TitleInput label='项目标段'>
          <QuerySelect disabled={!mainDepSelected} options={projectSection} value={param.section} onChange={setProjectSectionHandle} />
        </TitleInput> : null
      }
      {
        showSelect.includes('subProject') ?
        <TitleInput label='子工程'>
          <QuerySelect disabled={!mainDepSelected} options={subProject} value={param.subProject} onChange={setSubProjectValueHandle} />
        </TitleInput> : null
      }
      {
        showSelect.includes('construct') ?
        <TitleInput label='结构'>
          <QuerySelect disabled={!mainDepSelected} options={projectConstruct} value={param.construct} onChange={setConstructValueHandle} />
        </TitleInput>: null
      }
      {
        showSelect.includes('supplier') ?
        <TitleInput label='供应商'>
        <QuerySelect disabled={!mainDepSelected} options={projectSupplier} value={param.supplier} onChange={setSupplierValueHandle} />
      </TitleInput> : null
      }

      <Button style={{marginTop: '55px', height: '40px'}} onClick={resetAll} color={'#A1FFE4'}>
        重置
      </Button>
    </Space>
  </>
}

const TitleInput = (props: {label: string, children: ReactChild}) => {
  return <div>
    <div className='title'>
      <span
        style={{fontWeight: 500,
        fontSize: '20px',
        lineHeight: '22px'}}
      >
        {props.label}
      </span>
    </div>

    {props.children}
  </div>
}

export default SearchPanel