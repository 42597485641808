import { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { ProjectViewContainer as ProjectDetailsWrapper, Container } from '../project-view'
import { BottomLine } from '../project-view'
import ProjectTabs from '../../components/ProjectTabs'
import useCopyWriter from "../../hooks/use-copywriter"
import { findOption, filterChildren } from '../../components/SearchPanel/util'
import { useSearchParams } from "react-router-dom"
import { spliceProject } from '../project-query/util'
import { Space, Checkbox, Empty } from "antd"
import ProjectCheckBox from "../../components/ProjectCheckBox"
import { transformQuery } from './util'
import useProjectAllItem from "../../hooks/useProjectAllItem"

const ProjectDetails = () => {
  const t = useCopyWriter()
  const dataAll = Object.values(t('projectQuery'))

  const [searchParams] = useSearchParams()
  const { projectId, queryGroup } = Object.fromEntries(searchParams)
  const queryCheckBoxGroupData = useMemo(() => transformQuery(queryGroup, dataAll), [queryGroup, dataAll])
  const projectData = useProjectAllItem('1')
  const { projectInfo, sectionMockList, subProjectMockList, constructMockList, componentMockList, processMockList, materialMockList, projectAllUtil } = projectData
  const [sectionList, setSectionList] =  useState(spliceProject(sectionMockList))
  const [subList, setSubList] = useState(spliceProject(subProjectMockList))
  const [constructList, setConstructList] = useState(spliceProject(constructMockList))
  const [componentList, setComponentList] = useState(spliceProject(componentMockList))
  const [processList, setProcessList] = useState(spliceProject(processMockList))
  const [materialList, setMaterialList] = useState(spliceProject(materialMockList))
  const [supplier, setSupplier] = useState(spliceProject(projectAllUtil))
  
  const [checkAll, setCheckAll] = useState(true)
  const [checkSection, setSelectSection] = useState(true)
  const [checkSub, setCheckSub] = useState(true)
  const [checkConponent, setCheckComponent] = useState(true)
  const [checkPro, setCheckPro] = useState(true)
  const [checkAlls, setCheckAlls] = useState(true)
  const [checkMaterial, setCheckMaterial] = useState(true)

  const checkAllHandle = useCallback(({ target: {checked} }) => {
    setCheckAll(checked)
    if (!checked) {
      setSectionList([])
      setSubList([])
      // setConstructList([])
      setComponentList([])
      setProcessList([])
      setMaterialList([])
      setSupplier([])
    } else {
      setSectionList(spliceProject(sectionMockList))
      setSubList(spliceProject(subProjectMockList))
      // setConstructList(spliceProject(constructMockList))
      setComponentList(spliceProject(componentMockList))
      setProcessList(spliceProject(processMockList))
      setMaterialList(spliceProject(materialMockList))
      setSupplier(spliceProject(projectAllUtil))
    }
  }, [])
  const checkSectionHandle = useCallback(({ target: {checked} }) => {
    setSelectSection(checked)
    if (!checked) {
      setSectionList([])
    } else {
      setSectionList(spliceProject(sectionMockList))
    }
  }, [])
  const checkSubHandle = useCallback(({ target: {checked} }) => {
    setCheckSub(checked)
    if (!checked) {
      setSubList([])
    } else {
      setSubList(spliceProject(subProjectMockList))
    }
  }, [])

  const checkComponenetHandle = useCallback(({ target: {checked} }) => {
    setCheckComponent(checked)
    if (!checked) {
      setComponentList([])
    } else {
      setComponentList(spliceProject(componentMockList))
    }
  }, [])
  const checkProHandle = useCallback(({ target: {checked} }) => {
    setCheckPro(checked)
    if (!checked) {
      setProcessList([])
    } else {
      setProcessList(spliceProject(processMockList))
    }
  }, [])

  const checkAllsHandle = useCallback(({ target: {checked} }) => {
    setCheckAlls(checked)
    if (!checked) {
      setSupplier([])
    } else {
      setSupplier(spliceProject(projectAllUtil))
    }
  }, [])

  const checkMarterialHandle = useCallback(({ target: {checked} }) => {
    setCheckMaterial(checked)
    if (!checked) {
      setMaterialList([])
    } else {
      setMaterialList(spliceProject(materialMockList))
    }
  }, [])

  return <ProjectDetailsWrapper>
    <Container>
      <SubTitle>
        <span>{projectInfo.label}</span>
      </SubTitle>

      <Space size={'large'}>
        {/* <ProjectCheckBox>
          <Checkbox className="my-checkbox" onChange={checkAllHandle} checked={checkAll}>全部</Checkbox>
        </ProjectCheckBox> */}

        <ProjectCheckBox>
          <Checkbox className="my-checkbox" onChange={checkSectionHandle} checked={checkSection}>标段</Checkbox>
        </ProjectCheckBox>
        <ProjectCheckBox>
          <Checkbox className="my-checkbox" onChange={checkSubHandle} checked={checkSub}>子工程</Checkbox>
        </ProjectCheckBox>
        <ProjectCheckBox>
          <Checkbox className="my-checkbox" onChange={checkComponenetHandle} checked={checkConponent}>构件</Checkbox>
        </ProjectCheckBox>
        <ProjectCheckBox>
          <Checkbox className="my-checkbox" onChange={checkProHandle} checked={checkPro}>工序</Checkbox>
        </ProjectCheckBox>
        <ProjectCheckBox>
          <Checkbox className="my-checkbox" onChange={checkAllsHandle} checked={checkAlls}>总包</Checkbox>
        </ProjectCheckBox>
        <ProjectCheckBox>
          <Checkbox className="my-checkbox" onChange={checkMarterialHandle} checked={checkMaterial}>材料</Checkbox>
        </ProjectCheckBox>

        {/* {
          queryCheckBoxGroupData.length ?
            queryCheckBoxGroupData.map(query => 
              <Checkbox checked={true} key={query.id} className="my-checkbox" onChange={() => {}} children={<>{query.label}</>} />
            ) :
             */}
      </Space>

      <BottomLine  style={{marginTop: '10px'}} />

      { sectionList.map((section, index) => <ProjectTabs prefixs="标段" sub={true} key={index} tabs={section} setSubList={setSubList} />) }

      { subList.map((sub, index) => <ProjectTabs prefixs="子工程" sub={true} key={index} tabs={sub} setSubList={setConstructList} />) }

      { componentList.length ? <ProjectTabs prefixs="构件" sub={true} key={'any'} tabs={componentList[0]} setSubList={setProcessList} /> : null }

      { processList.map((sub, index) => <ProjectTabs prefixs="工序" sub={true} key={index} tabs={sub} setSubList={setMaterialList} />) }

      { materialList.map((sub, index) => <ProjectTabs prefixs="材料" sub={true} key={index} tabs={sub} setSubList={setSupplier} />) }

      { supplier.map((sub: any, index: any) => <ProjectTabs prefixs="总包" sub={true} key={index} tabs={sub} />) }
    </Container>
  </ProjectDetailsWrapper>
}

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 35px;
  margin-top: 82px;
  color: #ffffff;
  margin-bottom: 37px;
`

export default ProjectDetails