import React, { useCallback, useMemo } from 'react';
import styles from './index.module.less';
import dataAImg from '../../assets/images/firstPage/dataAImg.png';
import dataBImg from '../../assets/images/firstPage/dataBImg.png';
import cn from 'classnames';
import {Link, useNavigate} from 'react-router-dom'
import { LargeScreenTable } from '../../pages/large-screen2';
import useCopyWriter from '../../hooks/use-copywriter';


function LargeScreensData() {
  const t = useCopyWriter();
  const navigate=useNavigate()

  function onSeeDetail(){
    navigate('/largeScreen')
  }
  function onSeeDetail2(){
    navigate('/largeScreen2')
  }


  const dataSourceA = useMemo(()=>{
    const data= t("home.monitoringA");
    if(data){
      return data;
    }
    return []
  },[])

  const dataSourceB = useMemo(()=>{
    const data= t("home.monitoringB");
    if(data){
      return data;
    }
    return []
  },[])


  //瞎跳转，大屏数据和项目检索毫无关联，跳转就要一个效果,就这吧
  const seeDetails = useCallback(() => {
    const id=1;
    const query='%5B%5D';
    navigate(`/projectDetails?projectId=${id}&queryGroup=${query}`)
  }, [])

  const columns1 = [
    {
      title: '编号',
      dataIndex: 'id',
      width: '80px',
      textAlign: 'center'
    },
    {
      title: '项目名称',
      dataIndex: '',
      width: '160px',
      textAlign: 'center',
      render:(_text:string,record:any)=>(
        <span onClick={seeDetails}>{record.name}</span>
      )
    },
    {
      title: '碳排放（当前值/设计值/碳排比例）',
      dataIndex: 'currentValue',
      width: '400px',
      textAlign: 'center'
    },
    {
      title: '状态',
      dataIndex: 'options',
      width: '80px',
      textAlign: 'center',
      render: (text: string, record: any) => (
        <button className={cn(styles.tableButton,
          { [styles.greenBtn]: Number(record.status) === 1, [styles.redBtn]: Number(record.status) === 0 })
        }>
          {getMonitoringAState(Number(record.status))}
        </button>
      ),
    }
  ];

  return (
    <div className={styles.largeScreenData}>
      <div className={styles.dataA}>
        <div className={styles.dataAImg}>
          <img src={dataAImg} alt="" />
        </div>
        <div className={styles.dataListA}>
          <span className={styles.title}>实时监控数据大屏A</span>
           <div className={styles.table}>
              <LargeScreenTable
                    dataSource={dataSourceA}
                    columns={columns1}
                    tHeadStyle={{fontSize:'18px'}}
                    tBodyStyle={{fontSize:'14px'}}
                    tableClassName={styles.largeScreenTable}
                    divideLineClassName={styles.divideLine}
                    tHeadClassName={styles.tHead}
                    tRowClassName={styles.tRow}
                />
           </div>
          <div className={styles.seeDetailA}>
            <button className={styles.seeDetailBtn}  onClick={onSeeDetail}>查看详情</button>
          </div>
        </div>
      </div>

      <div className={styles.dataA}>
        <div className={styles.dataListB}>
          <span className={styles.title}>实时监控数据大屏B</span>
          <div className={styles.table}>
              <LargeScreenTable
                    dataSource={dataSourceB}
                    columns={columns1}
                    tHeadStyle={{fontSize:'18px'}}
                    tBodyStyle={{fontSize:'14px'}}
                    tableClassName={styles.largeScreenTable}
                    divideLineClassName={styles.divideLine}
                    tHeadClassName={styles.tHead}
                    tRowClassName={styles.tRow}
                />
           </div>
          <div className={styles.seeDetailB}>
            <button className={styles.seeDetailBtn} onClick={onSeeDetail2}>查看详情</button>
          </div>
        </div>
        <div className={styles.dataBImg}>
          <img src={dataBImg} alt="" />
        </div>
      </div>
    </div>
  );
}

export default LargeScreensData;


export function getMonitoringAState(state:number){
  if(state===1){
    return "正常"
  }else if(state===0){
    return "预警"
  }
}