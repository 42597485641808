import { ReactChild } from "react"
import styled from "styled-components"

const ProjectCheckBox = (props: {
  children: ReactChild
}) => {
  return <CheckBoxWrapper>
    {
      props.children
    }
  </CheckBoxWrapper>
}

const CheckBoxWrapper = styled.div`
  display: inline-block;
  width: 80px;
  height: 25px;
  background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.1476) 0%, rgba(255, 255, 255, 0.144) 110.84%);
  box-shadow: 0px 2.675px 7.35625px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(28.0875px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ProjectCheckBox