import { findOption } from './../components/SearchPanel/util';
import * as words from "../assets/copywriter/config";
import get from "lodash/get";

export default function useCopyWriter() {
  return function t(key: string) {
    return get(words, key);
  };
}

export function useFindParentById(id: string, FindType: number) {
  const t = useCopyWriter()
  const dataAll = t('projectQuery')

  function find(id: string): any {
    const currentItem: { [k in string]: any } = findOption(id, Object.values(dataAll))
    const { type, parent } = currentItem
    if (FindType !== type) {
      if (!parent) return
      return find(parent)
    } else {
      return currentItem
    }
  }

  return find(id)
}
