import { useMemo } from 'react';
import { Query } from '../../components/SearchPanel';
import { useURLQueryParam } from "../../hooks/useURLParams";

export function spliceProject(data: any[]): any[] {
  const result = []
  for(let i = 0; i < data.length; i+=5){
    result.push(data.slice(i,i+5));
 }
  return result
}

export const useProjectSearchParams = () => {
  const [params, setParam] = useURLQueryParam(
    ['project', 'section', 'subProject', 'construct', 'component', 'process', 'material', 'supplier']
  )

  return [
    useMemo(() => params,[params]),
    setParam
  ] as const
}

export const useConditionName = () => {
  const nameMap: Query = {
    project: '项目名称',
    section: '标段',
    subProject: '子工程',
    construct: '结构',
    component: '构件',
    process: '工序',
    material: '材料',
    supplier: '供应商',
  }

  return (key: keyof Query) => {
    if (nameMap[key]) {
      return nameMap[key]
    } else {
      return ''
    }
    // return nameMap[key]
  }
}