
import cx from "classnames";
import useCopyWriter from "../../hooks/use-copywriter";
import ProjectCard from "../../components/project-card";
import styles from "./index.module.less";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LargeScreensData from "../../components/large-screen-data";
import banner from '../../assets/images/firstPage/banner1.png';
import arrow1 from '../../assets/images/firstPage/arrow1.png';
import arrow2 from '../../assets/images/firstPage/arrow2.png';



export default function Home() {
  const t = useCopyWriter();

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
  };

  const settings = {
    className: "center",
    infinite: true,
    autoplaySpeed: 2000,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesPerRow: 2,
    arrows: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    dots: true,
  };
  return (
    <>
      <div className={styles.home_brand}>
        <div className={cx(styles.content)}>
          <Slider {...settings1}>
              <img src={banner} alt="" />
              <img src={banner} alt="" />
              <img src={banner} alt="" />
              <img src={banner} alt="" />
          </Slider>
          <a className={styles.loadingBtn} href="#homeProjects">
            <div className={styles.round}>
              <div className={styles.switchBtn}>
                <span className={styles.columnLine}></span>
              </div>
              <div className={styles.arrows}>
                <img src={arrow1} className={styles.arrow} alt="" />
                <img src={arrow2} className={styles.arrow} alt="" />
                <img src={arrow2} className={styles.arrow} alt="" />
              </div>
            </div>
          </a>
        </div>
      </div>

      <div className={styles.home_projects} id="homeProjects">
        <div className="container">
          <div className={styles.title}>项目快速入口</div>
          <div className={styles.list}>
            <Slider {...settings}>
              {t("home.projects").map((item: any, index: number) => (
                <ProjectCard
                  key={index}
                  title={item.title}
                  link={item.link}
                  cover={item.cover}
                />
              ))}
            </Slider>
            {/* */}
          </div>
        </div>
      </div>

      <div className={styles.home_screen}>
        <div className="container">
          <div className={styles.title}>实时监控大屏</div>
          <LargeScreensData />
        </div>
      </div>
    </>
  );
}
