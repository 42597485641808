import React, { Component } from "react";
import ReactLightCalendar from "@lls/react-light-calendar";
//import "@lls/react-light-calendar/dist/index.css";//去掉标题的样式
import './Calendar.module.less'

const DAY_LABELS = [
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "日"
];
const MONTH_LABELS = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月"
];

class Calendar extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const startDate = date.getTime()-(4*24*60*60*1000);
    // Get initial startDate and endDate
    this.state = {
      startDate: 1646697600000,
      endDate: 1647043200000
    };
  }

  componentDidMount(){
    document.getElementsByClassName('rlc-prev-month')[0].innerHTML=''
    document.getElementsByClassName('rlc-next-month')[0].innerHTML=''
    //document.getElementsByClassName('rlc-month-and-year')[0].innerHTML='2022年3月'
  }

  onChange = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  }

  render = () => {
    const { startDate, endDate } = this.state;

    return (
      <ReactLightCalendar
        range={true}
        displayTime={true}
        dayLabels={DAY_LABELS}
        monthLabels={MONTH_LABELS}
        onChange={this.onChange}
        startDate={startDate}
        endDate={endDate}
        {...this.props} // Add parent's additionnal props
      />
    );
  };
}

export default Calendar;