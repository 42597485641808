import useCopyWriter from "../../hooks/use-copywriter";
import { ProjectTypeCard } from '../../components/project-type-card'
import { Row, Col, Tag } from 'antd'
import iconWarn from '../../assets/img/project-view/warn.png'
import iconNormal from '../../assets/img/project-view/normal.png'
import iconHistory from '../../assets/img/project-view/history.png'
import backgroundImg from '../../assets/img/project-view/itemBackground.png'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react'
import ProjectSelect, { MonthSelect } from '../../components/project-select'
import { Button } from '../../components/project-type-card'
import ProjectTable from '../../components/ProjectTable'
import downArrow from '../../assets/img/project-view/down-arrow.png'
import React, {useState, useCallback, useEffect, useLayoutEffect} from "react"
import { optionsMock, optionMonthMock } from './data'
import { useInView } from 'react-intersection-observer';

const ProjectView: React.FC = () => {
  const t = useCopyWriter();
  const dataSourceMock: any[] = t('projectView.projectList').slice(0, 10)
  const [sortUp, setSortUp] = useState<boolean>(false)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  })
  const columns = [
    {
      title: '项目编号',
      dataIndex: 'id',
      span: 2
    },
    {
      title: '项目名称',
      dataIndex: 'name',
      span: 2,
      render(value: string, project: any) {
        return <TextEllipsis title={value}>
          {value}
        </TextEllipsis>
      }
    },
    {
      title: <TextEllipsis title={'项目碳排放量（当前值/设计值）（t）'}>
        项目碳排放量（当前值/设计值）（t）
      </TextEllipsis>,
      render(value: any, project: any) {
        return <React.Fragment>
          <span>{project.currentValue} </span>
          <span style={{marginLeft: '10px'}}>{project.designValue} </span>
          <span style={{marginLeft: '10px'}}>{project.percentage}</span>
        </React.Fragment>
      },
      span: 7
    },
    {
      title: '项目状态',
      dataIndex: 'status',
      render(value: any) {
        if(value === 0) {
          return <Tag color="#D01864">预警</Tag>
        } else {
          return <Tag color="#5ED3A7">正常</Tag>
        }
      },
      span: 2
    },
    {
      title: '项目类型',
      dataIndex: 'type',
      span: 2,
      render(value: any) {
        return <span>{['所有', '桥梁', '隧道', '道路', '建筑', '地铁'][~~value]}</span>
      }
    },
    {
      title: <div onClick={() => {
        setSortUp(sort => !sort)
      }} style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
        <TextEllipsis title={'项目体量（万元）'}>项目体量（万元）</TextEllipsis>
        {
          sortUp ? <SortIconUp src={downArrow} alt="up" /> : <SortIconDown src={downArrow} alt="down"/>
        }
      </div>,
      dataIndex: 'volume',
      span: 4
    },
    {
      title: '管理部门',
      dataIndex: 'department',
      span: 2
    },
    {
      title: '责任单位',
      dataIndex: 'duty',
      span: 2
    }
  ]
  const [dataSource, setDataSource] = useState(dataSourceMock)
  const filterDataSource = useCallback((status) => {
    if (!inView) {
      const el = document.getElementById('table-wrapper-scroll')
      el?.scrollIntoView()
    }
    setDataSource(dataSourceMock.filter(item => item.status === status))
  }, [inView]) 

  const [options, setOptions] = useState(optionsMock([15, 35, 30, 25]))
  const [echartsRender, setEchartsRender] = useState(false)
  useLayoutEffect(() => {
    setTimeout(() => {
      setEchartsRender(true)
    }, 200)
  }, [])
  const nowMonth: number = (new Date()).getMonth() + 1
  const [optionMonth, setOptionsMonth] = useState(optionMonthMock([12, 1,  2,  3, 4, 5, 6, 7, 11, 10, 9, 10], [12, 9, 12, 3, 6, 7, 8, 9, 3, 5, 7, 10]))

  const [monthValue, setMonthValue] = useState(null)
  const [projectType, setProjectType] = useState('0')
  const [tableSearchType, setTableSearchType] = useState(null)

  useEffect(() => {
    if (projectType === '1') {
      setOptions(optionsMock([24, 10, 5, 59]))
    } else if (projectType === '2') {
      setOptions(optionsMock([59, 15, 10, 22]))
    } else {

    }
  }, [projectType])
  const changeSelectMonth = useCallback((month) => {
    const arg1 = [], arg2 = []
    let i = 12
    while (i--) {
      arg1.push(
        Math.floor(Math.random() * (12 - 1+1)) + 1
      )
      arg2.push(
        Math.floor(Math.random() * (12 - 1+1)) + 1
      )
    }
    setMonthValue(month)
    setOptionsMonth(optionMonthMock(arg1, arg2))
  }, [])
  const seeDetails = useCallback(() => {
    if (tableSearchType === '0') return dataSourceMock
    return setDataSource(dataSourceMock.filter((item, index) => item.type == tableSearchType))
    // if (tableSearchType === '1') {
      
    // } else if (tableSearchType === '2') {
    //   setDataSource(dataSourceMock.filter((item, index) => index%2 !== 0))
    // } else {
    //   setDataSource(dataSourceMock)
    // }
  }, [tableSearchType])

  return <ProjectViewContainer>
    <Container>
      <Title>
        项目总览
      </Title>

      <Row style={{'marginTop': '82px'}}  justify={'space-between'}>
        <Col span={9}>
          <Card>
            <Header>
              <div className="left_text">
                <span>在执行项目碳排放总览</span>
              </div>
              <div className="control">
                <ProjectSelect onChange={(value: any) => setProjectType(value)} value={projectType} />
              </div>
            </Header>
            <BottomLine />
            {
              echartsRender ? 
              <div className="content-echarts">
                <ReactECharts
                  option={options}
                  style={{height: 200}}
                />
              </div> : null
            }
           
          </Card>
        </Col>
        <Col span={14}>
          <Card>
            <Header>
              <div className="left_txt">
                <span>在执行项目碳排放数据统计</span>
              </div>
              <div className="control">
                <MonthSelect  value={monthValue} onChange={changeSelectMonth} />
                <div className="line"></div>
              </div>
            </Header>
            <BottomLine />
            <div style={{marginTop: '11px'}}>
              <EchartsTitle className="info">
                <div>实际碳排放：65656 t</div>
                <div style={{marginLeft: '30px'}}>预测碳排放：85656 t</div>
              </EchartsTitle>
              <ReactECharts
                option={optionMonth}
                style={{height: 250}}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={35} style={{
        marginTop: '38px'
      }} justify="space-between" id={'table-wrapper-scroll'}>
        <Col span={8}>
          <ProjectTypeCard
            icon={iconWarn}
            title={'碳排放异常项目'}
            statusDes={'预警及超标'}
            id={'183'}
            background={backgroundImg}
            theme={'#D01864'}
            viewDetail={() => filterDataSource(0)}
          />
        </Col>
        <Col span={8}>
          <ProjectTypeCard
            icon={iconNormal}
            title={'在执行项目'}
            statusDes={'状态正常'}
            id={'256'}
            background={backgroundImg}
            theme={'#3259D2'}
            viewDetail={() => filterDataSource(1)}
          />
        </Col>
        <Col span={8}>
          <ProjectTypeCard
            icon={iconHistory}
            title={'历史项目'}
            statusDes={'已监控工程'}
            id={'179'}
            background={backgroundImg}
            theme={'#F9B02B'}
          />
        </Col>
      </Row>

      <div className="project_details" style={{marginTop: '80px'}}>
        <Row>
          <Col>
            <Title>项目详情</Title>
          </Col>
          <Col style={{ paddingTop: '12.25px', marginLeft: '27px'}}>
            <ProjectSelect onChange={(value: any) => {
              setTableSearchType(value)
            }} value={tableSearchType} />
          </Col>
          <Col style={{marginLeft: '10px'}}>
            <Button onClick={seeDetails} color={'#fff'}>
              查看详情
            </Button>
          </Col>
        </Row>

        <Card ref={ref} style={{marginTop: '33px', overflow: 'hidden', height: 'auto'}}>
          <ProjectTable firstSpan={2} columns={columns} rowKey={(item: any): number => item.id} dataSource={dataSource} />
        </Card>
      </div>
    </Container>
  </ProjectViewContainer>
}

const TextEllipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  transition: all .2s;
  box-sizing: border-box;
  white-space: nowrap;
`

const SortIconDown = styled.img`
  position: relative;
  cursor: pointer;
  margin-left: 10px;
`

const SortIconUp = styled.img`
  position: relative;
  margin-left: 10px;
  cursor: pointer;
  transform: rotate(180deg);
`

const EchartsTitle = styled(Row)`
  font-weight: 500;
  position: absolute;
  right: 0;
  left: 40px;
  font-size: 20px;
  /* padding-left: 40px; */
  color: #ffffff;
  line-height: 22px;
`

export const BottomLine = styled.div`
    bottom: 2px;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #33388E 0%, #FCFCFF 51.56%, #32388D 100%);
    border-radius: 8px;
    background-size: 100% 100%;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 38px;
  color: #fff;
  font-weight: 500;
  position: relative;
  font-size: 24px;
  .left_tet {
    flex: 1;
  }
  .right-control {
    flex: 0 0 100px;
  }
`

export const Card = styled.div`
  background: linear-gradient(175deg, #3851A9 0%, rgba(47, 21, 101, 0) 100%) no-repeat center;
  background-size: 100% 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 348px;
  width: 100%;
  box-sizing: border-box;
  .content-echarts {
    padding-top: 58px;
    width: 100%;
    height: calc(100% - 63px);
    overflow: hidden;
    position: relative;
  }
`

export const ProjectViewContainer = styled.div`
  padding: 0 50px;
  padding-top: 102px;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  background: linear-gradient(
    90deg,
    #213e61 5.74%,
    #093b83 48.45%,
    #171c70 98.97%
  );
  .title {
    font-size: 35px;
    color: #ffffff;
  }
`
export const Container = styled.div`
  margin: 0 auto;
  max-width: 80%;
  @media (max-width: 1680px) {
    max-width: 1580px;
  }
`

const Title = styled.span`
  font-size: 35px;
  color: #ffffff;
`

export default ProjectView