import React, { CSSProperties, useEffect, useLayoutEffect, useState } from 'react';
import styles from './index.module.less';
import cn from 'classnames';
import { ChartsTitle, LargeScreenTitle } from '../large-screen';
import liveView1 from '../../assets/images/largeScreen2/liveView1.png';
import liveView2 from '../../assets/images/largeScreen2/liveView1.png';
import liveView3 from '../../assets/images/largeScreen2/liveView1.png';
import liveView4 from '../../assets/images/largeScreen2/liveView1.png';
import { Space } from 'antd';
import ReactEcharts from "echarts-for-react"
import anime from 'animejs';


const lineChartData = {
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['0:00', '2:00', '4:00', '6:00', '8:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00', '0:00']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [80, 82, 84, 86, 88, 90, 92, 100, 105, 110],
      type: 'line',//设置图表类型为折线（面积）
      areaStyle: {
        //设置区域的样式
        //线性渐变的面积
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0, color: '#5463FA' // 0% 处的颜色
          }, {
            offset: 1, color: '#752ED8' // 100% 处的颜色
          }],
          global: false // 缺省为 false
        }

      },
      symbol: 'circle',//设定为实心点
      symbolSize: '6',//设定实心点的大小
      itemStyle: {
        normal: {
          color: "#F2F832", //折线点的颜色
          lineStyle: {
            color: "#5B8FF9" //折线的颜色
          }
        }
      },
    }
  ]
};


function LargeScreen2() {

  const [echartRender, setEchartsRender] = useState(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      setEchartsRender(true)
    }, 200)
  }, [])

  useEffect(() => {
    const basicInfoList: any = [
      { target: '#design', width: ['0%', '40%'] },
      { target: '#actual', width: ['0%', '52%'] },
      { target: "#exceed", width: ['0%', '20%'] }
    ]
    basicInfoList.map((item: any) => {
      return anime({
        targets: `${item.target}`,
        width: item.width,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: false
      });
    })


  }, [])
  return (
    <div className={styles.largeScreen2}>
      <div className={styles.content}>
        <div className={styles.menuDividingLine}></div>
        <div className={cn("container", styles.charts)}>
          <LargeScreenTitle>公共工程建设碳排放计算管理平台实时监控大屏</LargeScreenTitle>
          <div className={styles.displayData}>
            <div className={styles.leftData}>
              <div className={styles.basicInfo}>
                <ChartsTitle>基础信息</ChartsTitle>
                <div className={styles.infoOption}>
                  <p>项目名称：长江大桥项目</p>
                  <p>标段：标段1</p>
                  <p>所在地区：XX省XX市XXXXXXXXX</p>
                  <p>分包单位：XXXXXXXX建设公司</p>
                  <p>设计碳排放量：<span className={styles.designCarbon} id="design"></span>
                    <span className={styles.capacity}>1000T</span>
                  </p>
                  <p>实际碳排放量：<span id="actual" className={styles.actualCarbon}></span>
                    <span className={styles.capacity}>1200T</span>
                  </p>
                  {/* white-space: pre-wrap;*/}
                  <p>超标比例：        <span className={styles.percentageFather}>
                    <span id="exceed" className={styles.percentageSon}></span>
                  </span>
                    <span className={styles.capacity20}>20%</span>
                  </p>
                </div>
              </div>
              <div className={styles.liveView}>
                <ChartsTitle>实景图</ChartsTitle>
                <div className={styles.liveViewImages}>
                  <img src={liveView1} alt="" className={styles.liveViewImg1} />
                  <img src={liveView2} alt="" className={styles.liveViewImg2} />
                  <img src={liveView3} alt="" className={styles.liveViewImg3} />
                  <img src={liveView4} alt="" className={styles.liveViewImg4} />
                </div>
              </div>
            </div>
            <div className={styles.rightData}>
              <div className={styles.reasonsExceedingStandard}>
                <ChartsTitle>超标原因</ChartsTitle>
                <div className={styles.tableDisplay}>
                  {
                    echartRender && <LargeScreenTable tableClassName={styles.largeScreenTable} tHeadStyle={{ fontSize: '14px' }} tBodyStyle={{ fontSize: '14px' }} />
                  }
                </div>
              </div>
              <div className={styles.monitoringChart}>
                <ChartsTitle>碳排放量实时监控图</ChartsTitle>
                <div className={styles.foldingLineChart}>
                  {
                    echartRender && <ReactEcharts option={lineChartData} className={styles.lineChartData} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LargeScreen2;


export const columns1 = [
  {
    title: '子工程名称',
    dataIndex: 'projectItemName',
    width: '15%'
  },
  {
    title: '构件',
    dataIndex: 'GouJian',
    key: 'GouJian',
    width: '10%'
  },
  {
    title: '构造',
    dataIndex: 'GouZao',
    key: 'GouJian',
    width: '5%'
  },
  {
    title: '所属结构',
    dataIndex: 'Structure',
    key: 'Structure',
    width: '10%'
  },
  {
    title: '分包单位',
    dataIndex: 'Subcontractor',
    width: '15%'
  },
  {
    title: '碳排放超标比例',
    dataIndex: 'PercentageExceedance',
    width: '15%',
    color: '#D01864'
  },
  {
    title: '超标原因',
    dataIndex: 'ReasonsExceedingStandard',
    width: '20%',
    render: () => (
      <Space>
        <span>运输原因</span>
        <span style={{ color: '#5ED3A7' }}>查看详情</span>
      </Space>
    )
  },
  {
    title: '操作状态',
    dataIndex: 'options',
    width: '20%',
    render: (text: string, record: any) => (
      <Space size="middle">
        <span>已处理</span>
        <span style={{ color: '#5ED3A7' }}>查看详情</span>
      </Space>
    ),
  }
];

const data = [
  {
    key: '1',
    projectItemName: 'XXXXXXXX1',
    GouJian: '构件1',
    GouZao: 'XXX',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '2',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件2`,
    GouZao: 'XXX2',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '3',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件3`,
    GouZao: 'XXX3',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '1',
    projectItemName: 'XXXXXXXX1',
    GouJian: '构件1',
    GouZao: 'XXX',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '2',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件2`,
    GouZao: 'XXX2',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '3',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件3`,
    GouZao: 'XXX3',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '1',
    projectItemName: 'XXXXXXXX1',
    GouJian: '构件1',
    GouZao: 'XXX',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '2',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件2`,
    GouZao: 'XXX2',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '3',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件3`,
    GouZao: 'XXX3',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '2',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件2`,
    GouZao: 'XXX2',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
  {
    key: '3',
    projectItemName: 'XXXXXXXX',
    GouJian: `构件3`,
    GouZao: 'XXX3',
    Structure: '结构1',
    Subcontractor: 'XXXXXXXX',
    PercentageExceedance: '20%'
  },
];
interface TableProps {
  tableClassName?: string;//设置tBody的样式，比如max-height:300px;超出后出现滚动条
  tHeadStyle?: CSSProperties;//表格标题的style值是对象
  tBodyStyle?: CSSProperties;//表格tBody的style
  tHeadClassName?: string;//表格标题的className
  tRowClassName?: string;//最初的想法用于设置行高
  columns?: any[],
  dataSource?: any[],
  divideLineClassName?: string;//分割线的样式
}
export function LargeScreenTable(props: TableProps) {
  const { divideLineClassName, tableClassName, tBodyStyle = {}, tHeadStyle = {}, tHeadClassName = '', tRowClassName = '', columns = columns1, dataSource = data } = props;
  return (
    <div className={cn(styles.table)}>
      <div className={styles.tHeader}>
        {
          columns.map((item, index) => (
            <div key={index}
              className={cn(styles.tableHead, tHeadClassName)}
              style={{ width: item.width || '', ...tHeadStyle, background: item.background || '', textAlign: item.textAlign || 'center' }}
            >
              <span title={item.title}>{item.title}</span>
              {item.onClick && <>
                {
                  item.sortUp ?
                  <span className={styles.sortUp} onClick={item.onClick}></span>:<span onClick={item.onClick} className={styles.downUp} ></span>
                }
              </>
              }
            </div>
          ))
        }
      </div>
      <span className={cn(styles.tDivideLine, divideLineClassName)}></span>
      <div className={cn(styles.tBody, tableClassName)}>
        {
          columns.map((item, index) => (
            <div
              key={index}
              className={styles.thRow}
              style={{ width: item.width || '', ...tBodyStyle, background: item.background || '', textAlign: item.textAlign || 'center' }}>
              {dataSource.map((dataItem, index) => (
                <div
                  title={dataItem[item.dataIndex] || ''}
                  key={index}
                  className={cn(styles.trRow, tRowClassName)}
                  style={{ color: item.color || '' }}
                >
                  {
                    item.render ? item.render && item.render('', dataItem) : dataItem[item.dataIndex] || ''
                  }
                  
                </div>
              ))
              }
            </div>)
          )
        }
      </div>
    </div>
  )
}
