import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

interface ProjectTypeCardProps {
  title: string;
  icon: string;
  statusDes: string;
  id: string;
  viewDetail?: () => void;
  background: string;
  theme?: string;
}

export const ProjectTypeCard = (props: ProjectTypeCardProps) => {

  return <>
    <Container {...props} onClick={props.viewDetail}>
      <Icon {...props} />
      <div className={'project_des'}>
        <div className="project_title">
          <span>{ props.title }</span>
        </div>
        <div className="project_status">
          <span>{ props.statusDes }</span>
        </div>
        <div className="project_id">
          <span>{ props.id }</span>
        </div>
        <Button color={'#A1FFE4'}>
          查看详情...
        </Button>
      </div>
    </Container>
  </>
}

const Icon = (props: Partial<ProjectTypeCardProps>) => {
  return <>
    <TypeIcon {...props}>
      <div className={'inner'}>
        <img src={props.icon} alt="" />
      </div>
    </TypeIcon>
  </>
}

const Container = styled.div`
  background: url(${(props: Partial<ProjectTypeCardProps>) => props.background || 'xx'}) no-repeat center;
  background-size: 100% 100%;
  display: flex;
  cursor: pointer;
  padding: 7% 15%;
  padding-right: 3%;
  align-items: center;
  color: #ffffff;
  justify-content: space-between;
  .project_des {
    margin-left: 28px;
    flex: 1;
    overflow: hidden;
    .project_title {
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 24px;
      line-height: 29px;
      /* overflow: ; */
    }
    .project_status {
      font-size: 20px;
      margin-top: 12px;
    }
    .project_id{
      font-size: 20px;
      color: ${props => props.theme};
    }
    .view_details {
      padding: 4px 10px;
      border: 1px solid #A1FFE4;
      color: #A1FFE4;
      font-size: 14px;
      margin-top: 12.25px;
      border-radius: 7px;
      cursor: pointer;
      width: 100px;
      background: none;
    }
  }
`

export const Button = styled.button`
  ${props => `
    padding: 4px 10px;
    border: 1px solid ${props.color};
    color: ${props.color};
    font-size: 14px;
    margin-top: 12.25px;
    border-radius: 7px;
    cursor: pointer;
    width: 100px;
    background: none;
  `}
`

export const ButtonLink  = styled(Link)`
   ${props => `
    padding: 4px 10px;
    border: 1px solid ${props.color};
    color: ${props.color};
    font-size: 14px;
    margin-top: 12.25px;
    border-radius: 7px;
    cursor: pointer;
    width: 100px;
    background: none;
    text-align: center;
    line-height: 30px;
    &:hover {
      color: ${props.color}
    }
  `}
`

const TypeIcon = styled.div`
  ${props => css`
    border: 1px solid ${props.theme};
    width: 120px;
    height: 120px;
    @media (max-width: 1200px) {
      width: 80px;
      height: 80px;
      flex: 0 0 80px;
    }
    display: flex;
    flex: 0 0 120px;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    .inner {
      width: 100px;
      height: 100px;
      @media (max-width: 1200px) {
        width: 60px!important;
        height: 60px!important;
      }
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background: ${props.theme};
      img {
        width: 40px;
        height: 40px;
      }
    }
  `}
`