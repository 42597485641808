import { useMemo, useState } from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

export const cleanObject = <T extends object>(object: T): T => {
  let result: T | any = JSON.parse(JSON.stringify(object))
  Object.keys(result).forEach((key) => {
    const value = result[key]
    if (value === null || value === undefined || value === '') {
      delete result[key]
    }
  })
  return result
}
/**
 * 传入一个对象，和键集合，返回对应的对象中的键值对
 * @param obj
 * @param keys
 */
export const subset = <
  O extends { [key in string]: unknown },
  K extends keyof O
>(
  obj: O,
  keys: K[]
) => {
  const filteredEntries = Object.entries(obj).filter(([key]) =>
    keys.includes(key as K)
  )
  return Object.fromEntries(filteredEntries) as Pick<O, K>
}

export const useURLQueryParam = <K extends string>(keys: K[]) => {
  const [searchParams] = useSearchParams()
  const setSearchParams = useSetUrlSearchParam()
  const [stateKeys] = useState(keys)

  return [
    useMemo(
      () =>
        subset(Object.fromEntries(searchParams), stateKeys) as {
          [key in K]: string //eslint-disable-line
        },
      [searchParams, stateKeys] // eslint-disable-line
    ),
    (params: Partial<{ [key in K]: unknown }>) => {
      return setSearchParams(params)
    },
  ] as const
}

export const useSetUrlSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return (params: { [key in string]: unknown }) => {
    const cleanObj = cleanObject({
      ...Object.fromEntries(searchParams),
      ...params,
    }) as URLSearchParamsInit

    return setSearchParams(cleanObj)
  }
}

export const useResetURLParam = () => {
  const [param, setParam] = useSearchParams()
  const reset = () => {
    setParam({})
  }
  return reset
}
