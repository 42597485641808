import { Space, Tag, Divider } from 'antd'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import type { SelectOptions } from '../SearchPanel'
import { TweenOneGroup } from 'rc-tween-one';

const TagsGroup = (props: {
  group: SelectOptions[] | any[],
  deleteTag?: (...arg: any[]) => void
}) => {
  return <TagWrapper>
    <Space size={10}>
      <TweenOneGroup
        enter={{
          scale: 0.8,
          opacity: 0,
          type: 'from',
          duration: 100,
        }}
        onEnd={(e: any) => {
          if (e.type === 'appear' || e.type === 'enter') {
            e.target.style = 'display: inline-block';
          }
        }}
        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
        appear={false}
      >
       {
          props.group?.map(query => {
            return <Tag key={query.id}
              className='custom-tag'
              closeIcon={IconClose()}
              closable
              onClose={(e) => {
                // if (!query.parent) {
                //   e.preventDefault()
                //   return
                // }
                props.deleteTag && props.deleteTag(query.id)
              }}
            >
            {query.label}
          </Tag>
          })
        } 
      </TweenOneGroup>
      
    </Space>
  </TagWrapper>
}
const IconClose = () => {
  return <>
    <Divider style={{color: '#fff', background: '#fff', top: '-0.08em', margin: '0 6px'}} type="vertical" />
    <CloseOutlined style={{color: '#fff'}} />
  </>
}

const TagWrapper = styled.div`
  margin-top: 69px;
`

export default TagsGroup