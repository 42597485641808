import cx from "classnames";
import { Layout, Menu } from "antd";
import styles from "./index.module.less";
import { Link, useLocation } from 'react-router-dom'
import { useOnWindowScroll } from '../../hooks/useOnWindowScroll'
import { useEffect, useState, useCallback } from "react";

const { Header: AntHeader } = Layout;
const { SubMenu } = Menu;

export default function Header() {
  const [offsetOverflow, setOffsetOverflow] = useState(false)
  const [selectedKeys, setSelectKeys] = useState<any>(['1'])
  const location = useLocation()

  const handleClick = useCallback((e) => {
    setSelectKeys(e.key)
  }, [])

  useEffect(() => {
    const path = location.pathname
    setSelectKeys(location.pathname.slice(1, path.length) || 'home')
  }, [location])

  useOnWindowScroll(() => {
    if (window.pageYOffset > 30) {
      if (!offsetOverflow) setOffsetOverflow(true)
    } else {
      setOffsetOverflow(false)
    }
  })
  // menu key should be navigate pathname
  return (
    <div
      className={cx(styles.header)}
      style={
        {
          position: "fixed", zIndex: 100, width: "100%",
          background: `${
            offsetOverflow
            ? 'linear-gradient(229.24deg, #121c71 0%, #30186b 45.46%, #034087 69.19%, #1b155f 88.82%)'
            : ''
          }`
        }
      }
    >
      <AntHeader>
        <div className={styles.logo} />
        <Menu
          className={styles.menus}
          theme="dark"
          mode="horizontal"
          selectedKeys={selectedKeys}
          onClick={handleClick}
          defaultSelectedKeys={["1"]}
        >
          <Menu.Item key="home">
            <Link to={'/'}>首页</Link>
          </Menu.Item>
          <SubMenu key="project" title="项目统计">
            <Menu.Item key="projectView">
              <Link to="/projectView">项目总览</Link>
            </Menu.Item>
            <Menu.Item key="projectQuery">
              <Link to="/projectQuery">项目检索</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="material" title="构件统计">
            <Menu.Item key="materialStatistics">
              <Link to="/materialStatistics">构件总览</Link>
            </Menu.Item>
            <Menu.Item key="materialQuery">
              <Link to="/materialQuery">构件检索</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="all" title="工序统计">
            <Menu.Item key="totalPackageStatistics">
              <Link to="/totalPackageStatistics">
                工序总览
              </Link>
            </Menu.Item>
            <Menu.Item key="anthodiumQuery">
              <Link to="/anthodiumQuery">
                工序检索
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="alls" title="总包统计">
            <Menu.Item key="allStatistics">
              <Link to="/allStatistics">
                总包总览
              </Link>
            </Menu.Item>
            <Menu.Item key="allQuery">
              <Link to="/allQuery">
                总包检索
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="supplier" title="材料统计">
            <Menu.Item key="supplierStatistics">
              <Link to="/supplierStatistics">
                材料总览
              </Link>
            </Menu.Item>
            <Menu.Item key="supplierQuery">
              <Link to="/supplierQuery">
                材料检索
              </Link>
            </Menu.Item>
          </SubMenu>
          
          <SubMenu key="largeKey" title="数据大屏">
            <Menu.Item key="largeScreen">
              <Link to="/largeScreen">
                数据大屏1
              </Link>
            </Menu.Item>
            <Menu.Item key="largeScreen2">
              <Link to="/largeScreen2">
                数据大屏2
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </AntHeader>
    </div>
  );
}
