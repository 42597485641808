import { Line } from 'rc-progress'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import ReactECharts from 'echarts-for-react'
import { CSSProperties, useEffect, useState } from 'react';
import * as _ from 'lodash'

interface ItemProps {
  color: string,
  label: string,
  children: React.ReactChild;
  leftFlex?: number; // 24 -leftFlex = rightFlex
  style?: any
}

interface FillLineProps  {
  color: string; style?: any; label?: string; percent: number
}

interface EmissionVerticalProps {
  value: number[];
  color: string[];
  label: string[];
}

const typeMap = ['项目', '标段', '子工程', '结构', '构件', '工序', '材料', '供应商', '总包']

const FillLine = (props: FillLineProps) => {
  return <>
  <span style={{position: 'absolute', top: '-15px', marginLeft: '30%'}}>{props.label}</span>
  <Line
    style={props.style}
    percent={props.percent} 
    strokeWidth={6}
    trailWidth={6}
    trailColor={"transparent"}
    strokeColor={props.color}
  />
  </>
}

const NormalLine = (props: FillLineProps) => {
  return <>
    <span style={{position: 'absolute', top: '-18px', marginLeft: '50%'}}>{props.percent}%</span>
    <Line
      style={props.style}
      percent={props.percent} 
      strokeWidth={6}
      trailWidth={6}
      trailColor={"#FFFBFB"}
      strokeColor={props.color}
    />
  </>
}

export const EmissionVertical = (props: EmissionVerticalProps) => {
  const options = {
    tooltip : {
        trigger: 'axis',
        axisPointer : {            // 坐标轴指示器，坐标轴触发有效
            type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
        show: false
    },
    grid: {
        left: '-10%',
        right: '8%',
        top: '15%',
        bottom: '5%',
        containLabel: true
    },
    xAxis : [
      {
          type : 'category',
          data : ['当前碳值', '最近碳值'],
          axisTick: {
              show:false,
              alignWithLabel: false
          },
          axisLabel:{
              show:false,
          },
            axisLine: {
              show:false,
          },
          
      }
    ],
    yAxis : [
        {
            show:false,
            type : 'value'
        }
    ],
    series : [
        {
            type:'bar',
            barWidth: '25%',
            label:{
              normal:{
                  show:true,
                  position:'top',
                  formatter:'{c}T',
                  textStyle:{
                      color:'#fff',
                      fontSize: 16
                  }
              }  
            },
            itemStyle:{
              normal:{
                  borderRadius :100,
                  color:function(idx: any) {
                      var color = props.color
                      return color[idx.dataIndex % color.length]
                  }
              }  
            },
            data: props.value
        }
    ]
  };

  return <>
    <ReactECharts style={{height: '200px'}} option={options} />
    <Label style={{justifyContent: 'center'}} color={props.color[0]} label={props.label[0]} />
    <Label style={{justifyContent: 'center'}} color={props.color[1]} label={props.label[1]} />
  </>
}

const Emission = (props: {sub: boolean, options: any, prefix: string}) => {
  const prefix = props.sub ? '子工程' : '项目'
  const [emission, setEmission] = useState<any[]>([])
  const [actualEmission, setActualEmission] = useState<any[]>([])

  useEffect(() => {
    const { projectCurrentEmission, projectDesignedEmission, unitCurrentEmission, unitDesignedEmission } = props.options
    let emissionV: number[] = [projectCurrentEmission, projectDesignedEmission, unitCurrentEmission, unitDesignedEmission]
    if (props.sub) {
      const { subProjectCurrentEmission, subProjectDesignedEmission, unitCurrentEmission, unitDesignedEmission } = props.options
      emissionV = [subProjectCurrentEmission, subProjectDesignedEmission, unitCurrentEmission, unitDesignedEmission]
    }

    setActualEmission(_.cloneDeep(emissionV))
    const maxEmission = Math.max(...emissionV)
    emissionV.forEach((v: number, index: number) => {
      if (~~v !== maxEmission) {
        emissionV[index] = Number((v / maxEmission).toFixed(2)) * 100
      }
    })
    setEmission(emissionV)
  },[props.options])
  
  return emission.length ?
    <>
      <Item color='#61DDAA' style={{marginTop: '10px'}} label={`${props.prefix}当前碳排放量：`}>
        <FillLine color={'#61DDAA'} style={{marginTop: '8px'}} percent={emission[0]} label={`${actualEmission[0]}T`}  />
      </Item>
      <Item style={{marginTop: '15px'}} color='#F9B02B' label={`${props.prefix}设计碳排放量：`}>
        <FillLine color={'#F9B02B'} style={{marginTop: '8px'}} percent={emission[1]} label={`${actualEmission[1]}T`}  />
      </Item>

      <Item style={{marginTop: `${props.sub ? '10px' :'40px'}`}} color='#5889F0' label='单位当前碳排放量：'>
        <FillLine color={'#5889F0'} style={{marginTop: '8px'}} percent={emission[2]} label={`${actualEmission[2]}T`}  />
      </Item>
      <Item style={{marginTop: '15px'}} color='#5556E3' label='单位设计碳排放量：'>
        <FillLine color={'#5556E3'} style={{marginTop: '8px'}} percent={emission[3]} label={`${actualEmission[3]}T`}  />
      </Item>

      {
        props.sub ? <Item style={{marginTop: '20px'}} color='#AE30DA' label='设计排放量占总量比：'>
          <NormalLine color={'#AE30DA'} style={{marginTop: '4px'}} percent={props.options.emissionRatio} />
        </Item> : null
      }

      {
        props.sub ?
        <Item style={{marginTop: '25px'}} color='#D01864' label='施工进度：' leftFlex={7}>
          <NormalLine color={'#D01864'} style={{marginTop: '4px'}} percent={props.options.constructionProgress}  />
        </Item> :
        <Item style={{marginTop: '50px'}} color='#5556E3' label='施工进度：' leftFlex={7}>
          <NormalLine color={'#5556E3'} style={{marginTop: '4px'}} percent={props.options.constructionProgress}  />
        </Item>
      }
    </> : null
}

const Item = (props: ItemProps) => {
  return <>
    <Row justify={'space-between'} style={props.style}>
      <Col span={props.leftFlex ? props.leftFlex : 10}>
        <Label label={props.label} color={props.color} />
      </Col>
      <Col span={props.leftFlex ? (24 - props.leftFlex) : 14}>
        {props.children}
      </Col>
    </Row>
  </>
}

const Label = (props: {label: string, color: string, style?: CSSProperties}) => {
  return <LableWrapper style={props.style}>
    <Dot color={props.color} />
    <Name>{props.label}</Name>
  </LableWrapper>
}

const LableWrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  flex: 0 0 10px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 5px;
`

const Name = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
`
export default Emission
