import { Routes, Route } from "react-router-dom";
import Layout from "./layout";
import Home from "./pages/home";
import ProjectView from './pages/project-view'
import ProjectQuery from "./pages/project-query";
import "./App.less";
import useScrollTop from './hooks/useScrollTo'
import ProjectDetails from "./pages/project-details";
import LargeScreen from "./pages/large-screen";
import LargeScreen2 from "./pages/large-screen2";
import MaterialStatistics from "./pages/material-statistics";
import MaterialQuery from './pages/material-query'
import AnthodiumQuery from './pages/anthodium-query'
import SupplierQuery from './pages/supplier-query'
import TotalPackageStatistics from "./pages/total-package-statistics";
import SupplierStatistics from "./pages/supplier-statistics";
import AllQuery from './pages/all-query'
import AllStatistics from './pages/all-statistics'

function App() {
  useScrollTop()

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projectView" element={<ProjectView />} />
          <Route path="/largeScreen" element={<LargeScreen/>}/>
          <Route path="/projectQuery" element={<ProjectQuery />} />
          <Route path="/projectDetails" element={<ProjectDetails />}></Route>
          <Route path="/largeScreen2" element={<LargeScreen2/>}/>
          <Route path="/materialStatistics" element={<MaterialStatistics/>}/>
          <Route path="/materialQuery" element={<MaterialQuery />} />
          <Route path="/anthodiumQuery" element={<AnthodiumQuery />}/>
          <Route path="/supplierQuery" element={<SupplierQuery />}/>
          <Route path="/totalPackageStatistics" element={<TotalPackageStatistics/>}/>
          <Route path="/supplierStatistics" element={<SupplierStatistics/>}/>
          <Route path="/allStatistics" element={<AllStatistics />}></Route>
          <Route path="/allQuery" element={<AllQuery />}></Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
