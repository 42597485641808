import React, { useEffect, useRef } from 'react';

export const useOnWindowScroll = (callback: (...arg: any[]) => any) => {
  const listener = useRef<void | null | any>(null);

  useEffect(() => {
    if (listener.current) {
      window.removeEventListener('scroll', listener.current);
    }
    listener.current = window.addEventListener('scroll', callback);
    return () => {
      listener.current && window.removeEventListener('scroll', listener.current);
    };
  }, [callback]);
};
