export const optionsMock = (data: number[]) => ({
  tooltip: {
    trigger: "item",
  },
  color: ["#D01864", "#F9B02B", "#61DDAA", '#6FD9FB'],
  legend: {
    top: "22%",
    orient: "vertical",
    left: "5%",
    icon: "circle",
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 20,
    textStyle: {
      color: "#fff",
      // lineHeight: 10,
    },
  },
  series: [
    {
      type: "pie",
      radius: ["50%", "80%"],
      center: ["70%", "50%"],
      labelLine: {
        show: true,
      },
      label: {
        textStyle: {
          fontSize: 14,
          color: "#fff",
        },
      },
      data: [
        { value: data[0], name: "超标总包" },
        { value: data[1], name: "预警总包" },
        { value: data[2], name: "低碳总包" },
        {
          value: data[3], name: '正常总包'
        }
      ],
    },
  ],
});

export const optionMonthMock = (arg1: number[], arg2: number[]) => ({
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["实际碳排放数值", "预测碳排放数值"],
    top: "0%",
    right: "3%",
    icon: "circle",
    orient: "vertical",
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 10,
    textStyle: {
      color: "#fff",
      // lineHeight: 10,
    },
  },
  grid: {
    left: "3%",
    right: "5%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    axisTick: {
      show: false,
    },
    type: "category",
    boundaryGap: false,
    name: "月",
    data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  },
  yAxis: {
    type: "value",
    splitLine: {
      show: true,
      color: ["rgba(255, 255, 255, 0.151934)"],
      lineStyle: {
        width: 0.5,
        opacity: 0.151934,
      },
    },
  },
  color: ["#F9B02B", "#D01864"],
  series: [
    {
      name: "实际碳排放数值",
      type: "line",
      // data: [12, 1,  2,  3, 4, 5, 6, 7, 11, 10, 9, 10]
      data: arg1.slice(0, new Date().getMonth() + 1),
    },
    {
      name: "预测碳排放数值",
      type: "line",
      // data: [12, 9, 12, 3, 6, 7, 8, 9, 3, 5, 7, 10]
      data: arg2,
    },
  ],
});
