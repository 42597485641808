import styled from "styled-components"
import { Tabs, Row, Col, Progress, Space } from 'antd'
import { BottomLine } from '../../pages/project-view'
import { Button, ButtonLink } from "../project-type-card"
import { useCallback, useLayoutEffect, useState } from "react"
import Emission, { EmissionVertical } from './Emission'
import { Link, useLocation } from "react-router-dom";
import { filterChildren } from '../../components/SearchPanel/util'
import useCopyWriter from '../../hooks/use-copywriter'
import { spliceProject } from '../../pages/project-query/util'
import PieChart from "./PieChart"
import FoldChart from "./FoldLine"
import WarnImg from '../../assets/img/project-view/warning.png'

const { TabPane } = Tabs

interface ProjectTabsProps {
  tabs: any[];
  seeDetails?: (id: string) => string;
  sub: boolean;
  setSubList?: (...arg: any) => void,
  prefixs: string
}

const ProjectTabs = (props: ProjectTabsProps) => {
  const t = useCopyWriter()
  const dataAll = Object.values(t('projectQuery'))
  const { pathname } = useLocation()
  const [showDetails, setShowDetails] = useState(false)
  const [echartRender, setEchartsRender] = useState(false)
  const typeMap = ['项目', '标段', '子工程', '结构', '构件', '工序', '材料', '供应商', '总包']

  useLayoutEffect(() => {
    const timeId = setTimeout(() => {
      setEchartsRender(true)
    }, 200)

    return () => clearTimeout(timeId)
  }, [])
 
  const detailsHandle = useCallback((id: string) => {
    if (pathname.includes('Query')) {
      if (props.seeDetails) {
        props.seeDetails(id)
      }
    } else {
      setShowDetails(!showDetails)
    }
  }, [pathname, showDetails, props])

  const changeHandle = (id: string) => {
    if (props.setSubList) {
      const subList = filterChildren(id, dataAll)
      props.setSubList(spliceProject(subList))
    }
  }

  return <ProjectTabsWrapper className="custom-project-tabs">
    <Tabs type="card" onChange={changeHandle}>
      {
        props.tabs.map(tab => {
          return <TabPane tab={<ProjectTab tab={tab.label} warning={tab.warning} />} key={tab.id}>
            {/* <div style={{overflow: "hidden", boxSizing: 'border-box'}}> */}
            <Row justify={'space-between'}>
              <TabItem span={8}>
                <Emission prefix={props.prefixs} options={tab} sub={props.sub} />
              </TabItem>

              <TabItem span={8}>
                <Row justify={'space-between'} gutter={15}>
                  <Col span={8}>
                    <div className="title">
                      <Space align="center">
                      {tab.buildWarning ? <img src={WarnImg} alt="warning" style={{width: '22px', height: '22px'}} /> : null}建材碳排放量
                      </Space>
                    </div>
                    <BottomLine />
                    {
                      echartRender ? <EmissionVertical color={['#3259D2', '#FFFFFF']} value={[tab.buildEmission?.currentEmission, tab.buildEmission?.designedEmission]} label={['当前碳排放量', '设计碳排放量']} /> : null
                    } 
                  </Col>
                  <Col span={8}>
                    <div className="title">
                      <Space align="center">
                      {tab.transportWarning ? <img src={WarnImg} alt="warning" style={{width: '22px', height: '22px'}} /> : null}运输碳排放量
                      </Space>
                    </div>
                    <BottomLine />
                    {
                      echartRender ? <EmissionVertical color={['#F9B02B', '#FFFFFF']} value={[tab.transportEmission?.currentEmission, tab.transportEmission?.designedEmission]} label={['当前碳排放量', '设计碳排放量']} /> : null
                    }
                  </Col>
                  <Col span={8}>
                    <div className="title">
                      <Space align="center">
                      {tab.constructWarning ? <img src={WarnImg} alt="warning" style={{width: '22px', height: '22px'}} /> : null}施工碳排放量
                      </Space>
                    </div>
                    <BottomLine />
                    {
                      echartRender ? <EmissionVertical color={['#D01864', '#FFFFFF']} value={[tab.constructionEmission?.currentEmission, tab.constructionEmission?.designedEmission]} label={['当前碳排放量', '设计碳排放量']} /> : null
                    }
                  </Col>
                </Row>
              </TabItem>

              <TabItem span={7} style={{paddingLeft: '22px'}}>
                <div style={{display: 'flex', justifyContent: 'right', position: 'absolute', right: '30px', top: '30px'}}>
                {
                  props.seeDetails ?  <ButtonLink to={props.seeDetails(tab.id)} target={'_blank'} color={'#A1FFE4'} style={{marginTop: 0,width: '130px', height:'40px'}}>
                  查看详情...
                </ButtonLink>
                  : <Button color={'#A1FFE4'} style={{marginTop: 0,width: '130px', height:'40px'}} onClick={() => detailsHandle(tab.id)}>
                  查看详情...
                </Button>

                }
                
                </div>
                

                <div style={{marginTop: '15px', fontSize: '18px', fontWeight: '500', lineHeight: '34px'}}>
                  {
                    typeMap[tab.type] == '总包' ? null :  <div><Label>{typeMap[tab.type]}编号：</Label>{tab.projectId || tab.id}</div>
                  }
                 {
                   typeMap[tab.type] == '材料' ? <div><Label>{typeMap[tab.type]}名称：</Label>{tab.projectName}</div>: <div><Label>{typeMap[tab.type]}名称：</Label>{tab.label.split('/').at(-1)}</div>
                 }
                  
                  {
                    typeMap[tab.type] == '构件' ?<div><Label>构件来源：</Label>{tab.projectProps || '来源1'}</div> : null
                  }
                  {
                    typeMap[tab.type] == '材料' ?<div><Label>材料类型：</Label>{tab.label}</div> : null
                  }
                  {
                    typeMap[tab.type] == '总包' ? null : <><div><Label>{typeMap[tab.type]}状态：</Label>{tab.warning ? '预警' : '正常'}</div>
                    {
                      typeMap[tab.type] == '材料' ? null : <div><Label>管理部门：</Label>{tab.projectAdmin}</div>
                    }
                    {
                      typeMap[tab.type] == '项目' ? <div><Label>总包单位：</Label>{tab.projectTotal}</div> : 
                        typeMap[tab.type] == '材料' ? null :  <div><Label>责任单位：</Label>{tab.projectTotal}</div>
                    }</>
                  }
                  {
                    typeMap[tab.type] == '总包' ? <><div><Label>碳排等级：</Label>{tab.projectLevel}</div><div><Label>碳排达标率：</Label>{tab.projectPercent}</div></> : null
                  }
                  {
                    typeMap[tab.type] == '材料' ?<div><Label>供应商：</Label>{tab.projectsupplier}</div> : null
                  }
                  {/* <div><Label>分包单位：</Label>上海市荣年建筑材料有限公司</div>
                  <div><Label>子工程类型：</Label> 南京</div>
                  <div><Label>工期：</Label> 南京</div>
                  <div><Label>体量：</Label>20.83km</div> */}
                </div>
              </TabItem>
            </Row>

            {
              showDetails &&
              <Row justify={'space-between'} style={{marginTop: '20px'}}>
                <TabItem span={9}>
                  <FoldChart design={tab.designedEmissionTrend} actually={tab.actuallyEmissionTrend} />
                </TabItem>
                <TabItem span={7}>
                  <div className="title">
                    <span>设计碳排放量构成</span>
                  </div>
                  <BottomLine />
                  <PieChart option={tab.designedEmissionRatio} />
                </TabItem>
                <TabItem span={7} style={{paddingLeft: '22px'}}>
                  <div className="title">
                    <span>实际碳排放量构成</span>
                  </div>
                  <BottomLine />
                  <PieChart option={tab.actuallyEmissionRatio} />
                </TabItem>
              </Row>  
            }
          </TabPane>
        })
      }
    </Tabs>
  </ProjectTabsWrapper>
}

const ProjectTab = ({tab, warning}: {tab: string, warning: boolean}) => {
  return <Space>
    {
      warning ? <img alt="warning" src={WarnImg}></img> : null
    }
    <span>{tab}</span>
  </Space>
}

const ProjectTabsWrapper = styled.div`
  margin-top: 40px;
`

const TabItem = styled(Col)`
  background: linear-gradient(174deg, #3851A9 0%, rgba(47, 21, 101, 0) 100%);
  filter: drop-shadow(0px 1px 4px rgba(255, 255, 255, 0.25));
  border-radius: 8px;
  padding: 14px 15px;
  .title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px;
    text-align: center;
  }
`

const ProgressLabel = styled.span`
  margin-left: 12px;
  font-size: 18px;
  font-weight: 500;
`

const Label = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 45px;
`

export default ProjectTabs