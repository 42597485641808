import { SelectOptions } from './../../components/SearchPanel/index';
import { findOption } from '../../components/SearchPanel/util'

export const transformQuery = (query: string, allData: any[]): SelectOptions[] => {
  const result: any[] = []
  const idGroup = JSON.parse(decodeURI(query)) as any[]
  idGroup.forEach((id: string) => {
    result.push(findOption(id, allData))
  })
  return result
}