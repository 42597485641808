import { useMemo } from 'react';
import { filterChildren, findOption } from '../components/SearchPanel/util';
import useCopyWriter from './use-copywriter';

export const getGroupSubFromCurGroup = (group: any[], dataAll: any[]) => {
  let result: any[] = []
  group.forEach((item: any) => {
    result = [...filterChildren(item.id, dataAll), ...result]
  })
  return result
}

const useProjectAllItem = (id: string) => {
  const t = useCopyWriter()
  const dataAll = Object.values(t('projectQuery'))

  const projectInfo = useMemo(() => findOption(id, dataAll), [id, dataAll])
  const sectionMockList = useMemo(() => filterChildren(id as string, dataAll), [id, dataAll])
  const subProjectMockList = useMemo(() => getGroupSubFromCurGroup(sectionMockList, dataAll), [sectionMockList, dataAll])
  let constructMockList: any[] = useMemo(() => getGroupSubFromCurGroup(subProjectMockList, dataAll), [subProjectMockList, dataAll])
  let componentMockList: any[] = useMemo(() => getGroupSubFromCurGroup(constructMockList, dataAll), [constructMockList, dataAll])
  let processMockList: any[] = useMemo(() => getGroupSubFromCurGroup(componentMockList, dataAll), [componentMockList, dataAll])
  let materialMockList: any[] = useMemo(() => getGroupSubFromCurGroup(processMockList, dataAll), [processMockList, dataAll])
  let supplierMockList: any[] = useMemo(() => getGroupSubFromCurGroup(materialMockList, dataAll), [materialMockList, dataAll])
  let projectAllUtil: any[] = useMemo(() => t('projectQuery.projectAllUtil'), [])

  return useMemo(() => {
    return {
      projectInfo,
      sectionMockList,
      subProjectMockList,
      constructMockList,
      componentMockList,
      processMockList,
      materialMockList,
      supplierMockList,
      projectAllUtil
    }
  }, [id])
}

export default useProjectAllItem