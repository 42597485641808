import { Select } from 'antd'

const Option = Select.Option

interface IdSelectionProps  {
  value?: any;
  onChange?: (value?: number) => void
  defaultOptionName?: string
  options?: { name: string; id?: number, value?: any }[]
  placeholder?: string
}

export const IdSelect = <T extends IdSelectionProps>(props: T) => {
  const { value, onChange, defaultOptionName, options, placeholder } = props
  return <>
    <Select
      value={value}
      style={{width: '130px'}}
      onChange={(value) => {
        onChange?.(value)
      }}
      placeholder={placeholder}
    >
      {defaultOptionName ? (
        <Select.Option value={0}>{defaultOptionName}</Select.Option>
      ) : null}
      {options
        ? options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.name}
            </Option>
          ))
        : null}
    </Select>
  </>
}
